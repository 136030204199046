<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <div>
    <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
      <a-form-item
        :label="$t('field.skuNo')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.skuNo',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.skuNo') },
                           { max: 100, message: this.$t('field.skuNo')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.skuNo"
          v-model="saleOrderItemListData.skuNo"
          :placeholder="'请输入'+ this.$t('field.skuNo')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.skuImage')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.skuImage',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.skuImage') },
                           { max: 100, message: this.$t('field.skuImage')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.skuImage"
          v-model="saleOrderItemListData.skuImage"
          :placeholder="'请输入'+ this.$t('field.skuImage')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.skuName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.skuName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.skuName') },
                           { max: 100, message: this.$t('field.skuName')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.skuName"
          v-model="saleOrderItemListData.skuName"
          :placeholder="'请输入'+ this.$t('field.skuName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.quantity')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.quantity',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.quantity') },
                           { max: 100, message: this.$t('field.quantity')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.quantity"
          v-model="saleOrderItemListData.quantity"
          :placeholder="'请输入'+ this.$t('field.quantity')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.tagPrice')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.tagPrice',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.tagPrice') },
                           { max: 100, message: this.$t('field.tagPrice')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.tagPrice"
          v-model="saleOrderItemListData.tagPrice"
          :placeholder="'请输入'+ this.$t('field.tagPrice')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.dealPrice')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.dealPrice',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.dealPrice') },
                           { max: 100, message: this.$t('field.dealPrice')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.dealPrice"
          v-model="saleOrderItemListData.dealPrice"
          :placeholder="'请输入'+ this.$t('field.dealPrice')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.totalAmount')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.totalAmount',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.totalAmount') },
                           { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.totalAmount"
          v-model="saleOrderItemListData.totalAmount"
          :placeholder="'请输入'+ this.$t('field.totalAmount')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.dealAmount')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.dealAmount',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.dealAmount') },
                           { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.dealAmount"
          v-model="saleOrderItemListData.dealAmount"
          :placeholder="'请输入'+ this.$t('field.dealAmount')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.tagPriceShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.tagPriceShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.tagPriceShow') },
                           { max: 100, message: this.$t('field.tagPriceShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.tagPriceShow"
          v-model="saleOrderItemListData.tagPriceShow"
          :placeholder="'请输入'+ this.$t('field.tagPriceShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.dealPriceShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.dealPriceShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.dealPriceShow') },
                           { max: 100, message: this.$t('field.dealPriceShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.dealPriceShow"
          v-model="saleOrderItemListData.dealPriceShow"
          :placeholder="'请输入'+ this.$t('field.dealPriceShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.totalAmountShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.totalAmountShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.totalAmountShow') },
                           { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.totalAmountShow"
          v-model="saleOrderItemListData.totalAmountShow"
          :placeholder="'请输入'+ this.$t('field.totalAmountShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.dealAmountShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.dealAmountShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.dealAmountShow') },
                           { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.dealAmountShow"
          v-model="saleOrderItemListData.dealAmountShow"
          :placeholder="'请输入'+ this.$t('field.dealAmountShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.unit')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.unit',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.unit') },
                           { max: 100, message: this.$t('field.unit')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.unit"
          v-model="saleOrderItemListData.unit"
          :placeholder="'请输入'+ this.$t('field.unit')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.tags')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-select mode="multiple" v-model="saleOrderItemListData.tags">
          <a-select-option
            v-for="(item, index) in codeList.tags"
            :key="index"
            :value="item.value">{{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        :label="$t('field.saleAttrName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.saleAttrList.saleAttrName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.saleAttrName') },
                           { max: 100, message: this.$t('field.saleAttrName')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.saleAttrList.saleAttrName"
          v-model="saleOrderItemListData.saleAttrList.saleAttrName"
          :placeholder="'请输入'+ this.$t('field.saleAttrName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.valueName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.saleAttrList.saleAttrValueList.valueName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.valueName') },
                           { max: 100, message: this.$t('field.valueName')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.saleAttrList.saleAttrValueList.valueName"
          v-model="saleOrderItemListData.saleAttrList.saleAttrValueList.valueName"
          :placeholder="'请输入'+ this.$t('field.valueName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.saleAttrValueDeleteFlag')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.saleAttrList.saleAttrValueList.saleAttrValueDeleteFlag',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.saleAttrValueDeleteFlag') },
                           { max: 100, message: this.$t('field.saleAttrValueDeleteFlag')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.saleAttrList.saleAttrValueList.saleAttrValueDeleteFlag"
          v-model="saleOrderItemListData.saleAttrList.saleAttrValueList.saleAttrValueDeleteFlag"
          :placeholder="'请输入'+ this.$t('field.saleAttrValueDeleteFlag')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.saleAttrDeleteFlag')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderItemListData.saleAttrList.saleAttrDeleteFlag',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.saleAttrDeleteFlag') },
                           { max: 100, message: this.$t('field.saleAttrDeleteFlag')+'长度小于等于100' }]}
          ]"
          name="saleOrderItemListData.saleAttrList.saleAttrDeleteFlag"
          v-model="saleOrderItemListData.saleAttrList.saleAttrDeleteFlag"
          :placeholder="'请输入'+ this.$t('field.saleAttrDeleteFlag')"/>
      </a-form-item>

    </a-card>
  </div>
</template>

<script>
export default {
  name: 'EditSaleOrderItem',
  props: {
    saleOrderItemData: {
      type: Object
    }
  },
  data () {
    return {
        codeList: {
  'isSelfDelivery': []
}
    }
  },
  methods: {

  }
}

</script>
<style>

</style>
