<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <div style="margin-left:10%">

    <div class="title">
      <p>原商品信息：{{ saleOrderData.saleOrderNo }}</p>
    </div>

    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="20" :sm="24">
            <a-form-item
              :label="$t('field.country')"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
              <a-select v-model="addressInfo.country" :placeholder="'请选择'+ this.$t('field.country')">
                <a-select-option
                  v-for="(item, index) in codeList.countryList"
                  :key="index"
                  :value="item.value">{{ item.value }}-{{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              :label="$t('field.city')"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
              <a-input
                name="addressInfo.city"
                v-model="addressInfo.city"
                :placeholder="'请输入'+ this.$t('field.city')"/>
             
            </a-form-item>

            <a-form-item
              :label="$t('field.state')"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
              <a-input
                name="addressInfo.state"
                v-model="addressInfo.state"
                :placeholder="'请输入'+ this.$t('field.state')"/>
              
            </a-form-item>

            <a-form-item
              :label="$t('field.room')"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
              <a-input
                name="addressInfo.room"
                v-model="addressInfo.room"
                :placeholder="'请输入'+ this.$t('field.room')"/>
            </a-form-item>

            <a-form-item
              :label="$t('field.house')"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
              <a-input
                name="addressInfo.house"
                v-model="addressInfo.house"
                :placeholder="'请输入'+ this.$t('field.house')"/>
            </a-form-item>

            <a-form-item
              :label="$t('field.street')"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
              <a-input
                name="addressInfo.street"
                v-model="addressInfo.street"
                :placeholder="'请输入'+ this.$t('field.street')"/>
            </a-form-item>

          </a-col>
        </a-row>
      </a-form>
    </div>

    <div style="text-align: center">
      <a-button @click="$emit('handleModifyReciveAddrCancel')">取消</a-button>
      <a-button style="margin-left: 10px" type="primary" @click="handleChangeReciveAddr(saleOrderData)">确定</a-button>
    </div>

  </div>

</template>

<script>
import { changeShippingAddress } from '@/api/order/saleOrder'

export default {
  name: 'ModifyReciveAddr',
  props: {
    saleOrderData: {
      type: Object
    },
    addressInfo: {
      type: Object
    }
  },

  data () {
    return {
      // param:{
      //  logisticsModel:null,
      //  shippingBillNo:''
      // },
      codeList: {
        'countryList': [
          { label: '美国', value: 'USA' },
          { label: '中国', value: 'CHN' },
      
        ],
      }
    }
  },
  methods: {

    handleChangeReciveAddr (record) {
      const that = this
      const param = {
        saleOrderNo: record.saleOrderNo,
        shippingAddress: this.addressInfo
      }
      this.$confirm({
        title: '确认修改收货地址信息?',
        // content: this.$t('message.information'),
        onOk () {
          console.log('OK')
          changeShippingAddress(param).then((res) => {
            console.log('handleChangeReciveAddr->changeShippingAddr', param, res)
            if (res.success) {
              that.$emit('handleModifyReciveAddrOk')
            } else {
              that.$message.error('提交失败,原因：' + res.respMsg)
            }
          }).catch(() => {
            that.$message.error('修改失败')
          })
        },
        onCancel () {
          console.log('Cancel')
        },
        class: 'test'
      })
    }

  }
}

</script>
<style>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  margin-bottom: 14px;
}
</style>
