var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{attrs:{"body-style":{padding: '24px 32px'},"bordered":false}},[_c('a-form-item',{attrs:{"label":_vm.$t('field.skuNo'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.skuNo',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.skuNo') },
                         { max: 100, message: this.$t('field.skuNo')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.skuNo',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.skuNo') },\n                         { max: 100, message: this.$t('field.skuNo')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.skuNo","placeholder":'请输入'+ this.$t('field.skuNo')},model:{value:(_vm.saleOrderItemListData.skuNo),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "skuNo", $$v)},expression:"saleOrderItemListData.skuNo"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.skuImage'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.skuImage',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.skuImage') },
                         { max: 100, message: this.$t('field.skuImage')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.skuImage',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.skuImage') },\n                         { max: 100, message: this.$t('field.skuImage')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.skuImage","placeholder":'请输入'+ this.$t('field.skuImage')},model:{value:(_vm.saleOrderItemListData.skuImage),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "skuImage", $$v)},expression:"saleOrderItemListData.skuImage"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.skuName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.skuName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.skuName') },
                         { max: 100, message: this.$t('field.skuName')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.skuName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.skuName') },\n                         { max: 100, message: this.$t('field.skuName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.skuName","placeholder":'请输入'+ this.$t('field.skuName')},model:{value:(_vm.saleOrderItemListData.skuName),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "skuName", $$v)},expression:"saleOrderItemListData.skuName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.quantity'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.quantity',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.quantity') },
                         { max: 100, message: this.$t('field.quantity')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.quantity',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.quantity') },\n                         { max: 100, message: this.$t('field.quantity')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.quantity","placeholder":'请输入'+ this.$t('field.quantity')},model:{value:(_vm.saleOrderItemListData.quantity),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "quantity", $$v)},expression:"saleOrderItemListData.quantity"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.tagPrice'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.tagPrice',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.tagPrice') },
                         { max: 100, message: this.$t('field.tagPrice')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.tagPrice',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.tagPrice') },\n                         { max: 100, message: this.$t('field.tagPrice')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.tagPrice","placeholder":'请输入'+ this.$t('field.tagPrice')},model:{value:(_vm.saleOrderItemListData.tagPrice),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "tagPrice", $$v)},expression:"saleOrderItemListData.tagPrice"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealPrice'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.dealPrice',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealPrice') },
                         { max: 100, message: this.$t('field.dealPrice')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.dealPrice',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealPrice') },\n                         { max: 100, message: this.$t('field.dealPrice')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.dealPrice","placeholder":'请输入'+ this.$t('field.dealPrice')},model:{value:(_vm.saleOrderItemListData.dealPrice),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "dealPrice", $$v)},expression:"saleOrderItemListData.dealPrice"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.totalAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalAmount') },
                         { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.totalAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalAmount') },\n                         { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.totalAmount","placeholder":'请输入'+ this.$t('field.totalAmount')},model:{value:(_vm.saleOrderItemListData.totalAmount),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "totalAmount", $$v)},expression:"saleOrderItemListData.totalAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.dealAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealAmount') },
                         { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.dealAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealAmount') },\n                         { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.dealAmount","placeholder":'请输入'+ this.$t('field.dealAmount')},model:{value:(_vm.saleOrderItemListData.dealAmount),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "dealAmount", $$v)},expression:"saleOrderItemListData.dealAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.tagPriceShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.tagPriceShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.tagPriceShow') },
                         { max: 100, message: this.$t('field.tagPriceShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.tagPriceShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.tagPriceShow') },\n                         { max: 100, message: this.$t('field.tagPriceShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.tagPriceShow","placeholder":'请输入'+ this.$t('field.tagPriceShow')},model:{value:(_vm.saleOrderItemListData.tagPriceShow),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "tagPriceShow", $$v)},expression:"saleOrderItemListData.tagPriceShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealPriceShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.dealPriceShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealPriceShow') },
                         { max: 100, message: this.$t('field.dealPriceShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.dealPriceShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealPriceShow') },\n                         { max: 100, message: this.$t('field.dealPriceShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.dealPriceShow","placeholder":'请输入'+ this.$t('field.dealPriceShow')},model:{value:(_vm.saleOrderItemListData.dealPriceShow),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "dealPriceShow", $$v)},expression:"saleOrderItemListData.dealPriceShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.totalAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalAmountShow') },
                         { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.totalAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalAmountShow') },\n                         { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.totalAmountShow","placeholder":'请输入'+ this.$t('field.totalAmountShow')},model:{value:(_vm.saleOrderItemListData.totalAmountShow),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "totalAmountShow", $$v)},expression:"saleOrderItemListData.totalAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.dealAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealAmountShow') },
                         { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.dealAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealAmountShow') },\n                         { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.dealAmountShow","placeholder":'请输入'+ this.$t('field.dealAmountShow')},model:{value:(_vm.saleOrderItemListData.dealAmountShow),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "dealAmountShow", $$v)},expression:"saleOrderItemListData.dealAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.unit'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.unit',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.unit') },
                         { max: 100, message: this.$t('field.unit')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.unit',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.unit') },\n                         { max: 100, message: this.$t('field.unit')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.unit","placeholder":'请输入'+ this.$t('field.unit')},model:{value:(_vm.saleOrderItemListData.unit),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "unit", $$v)},expression:"saleOrderItemListData.unit"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.tags'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-select',{attrs:{"mode":"multiple"},model:{value:(_vm.saleOrderItemListData.tags),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData, "tags", $$v)},expression:"saleOrderItemListData.tags"}},_vm._l((_vm.codeList.tags),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.saleAttrName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.saleAttrList.saleAttrName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.saleAttrName') },
                         { max: 100, message: this.$t('field.saleAttrName')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.saleAttrList.saleAttrName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.saleAttrName') },\n                         { max: 100, message: this.$t('field.saleAttrName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.saleAttrList.saleAttrName","placeholder":'请输入'+ this.$t('field.saleAttrName')},model:{value:(_vm.saleOrderItemListData.saleAttrList.saleAttrName),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData.saleAttrList, "saleAttrName", $$v)},expression:"saleOrderItemListData.saleAttrList.saleAttrName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.valueName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.saleAttrList.saleAttrValueList.valueName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.valueName') },
                         { max: 100, message: this.$t('field.valueName')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.saleAttrList.saleAttrValueList.valueName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.valueName') },\n                         { max: 100, message: this.$t('field.valueName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.saleAttrList.saleAttrValueList.valueName","placeholder":'请输入'+ this.$t('field.valueName')},model:{value:(_vm.saleOrderItemListData.saleAttrList.saleAttrValueList.valueName),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData.saleAttrList.saleAttrValueList, "valueName", $$v)},expression:"saleOrderItemListData.saleAttrList.saleAttrValueList.valueName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.saleAttrValueDeleteFlag'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.saleAttrList.saleAttrValueList.saleAttrValueDeleteFlag',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.saleAttrValueDeleteFlag') },
                         { max: 100, message: this.$t('field.saleAttrValueDeleteFlag')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.saleAttrList.saleAttrValueList.saleAttrValueDeleteFlag',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.saleAttrValueDeleteFlag') },\n                         { max: 100, message: this.$t('field.saleAttrValueDeleteFlag')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.saleAttrList.saleAttrValueList.saleAttrValueDeleteFlag","placeholder":'请输入'+ this.$t('field.saleAttrValueDeleteFlag')},model:{value:(_vm.saleOrderItemListData.saleAttrList.saleAttrValueList.saleAttrValueDeleteFlag),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData.saleAttrList.saleAttrValueList, "saleAttrValueDeleteFlag", $$v)},expression:"saleOrderItemListData.saleAttrList.saleAttrValueList.saleAttrValueDeleteFlag"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.saleAttrDeleteFlag'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderItemListData.saleAttrList.saleAttrDeleteFlag',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.saleAttrDeleteFlag') },
                         { max: 100, message: this.$t('field.saleAttrDeleteFlag')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderItemListData.saleAttrList.saleAttrDeleteFlag',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.saleAttrDeleteFlag') },\n                         { max: 100, message: this.$t('field.saleAttrDeleteFlag')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderItemListData.saleAttrList.saleAttrDeleteFlag","placeholder":'请输入'+ this.$t('field.saleAttrDeleteFlag')},model:{value:(_vm.saleOrderItemListData.saleAttrList.saleAttrDeleteFlag),callback:function ($$v) {_vm.$set(_vm.saleOrderItemListData.saleAttrList, "saleAttrDeleteFlag", $$v)},expression:"saleOrderItemListData.saleAttrList.saleAttrDeleteFlag"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }