import request from '@/utils/request'
const baseUrl = '/api'
export function saveDeliveryCompanyData (parameter) {
  let url = baseUrl + '/shipping/deliveryCompany/save'
  if (!parameter.deliveryCompanyId) {
    url = baseUrl + '/shipping/deliveryCompany/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchDeliveryCompany (parameter) {
  return request({
    url: baseUrl + '/shipping/deliveryCompany/search',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listDeliveryCompany (parameter) {
  return request({
    url: baseUrl + '/shipping/deliveryCompany/list',
    method: 'get',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getDeliveryCompanyData (parameter) {
  return request({
    url: baseUrl + '/shipping/deliveryCompany/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableDeliveryCompany (parameter) {
  return request({
    url: baseUrl + '/shipping/deliveryCompany/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableDeliveryCompany (parameter) {
  return request({
    url: baseUrl + '/shipping/deliveryCompany/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
