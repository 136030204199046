<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <div>
    <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
      <a-form-item
        :label="$t('field.saleOrderNo')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderNo',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.saleOrderNo') },
                           { max: 100, message: this.$t('field.saleOrderNo')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderNo"
          v-model="saleOrderData.saleOrderNo"
          :placeholder="'请输入'+ this.$t('field.saleOrderNo')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.shippingBillNo')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.shippingBillNo',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.shippingBillNo') },
                           { max: 100, message: this.$t('field.shippingBillNo')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.shippingBillNo"
          v-model="saleOrderData.shippingBillNo"
          :placeholder="'请输入'+ this.$t('field.shippingBillNo')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.saleOrderStatus')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderStatus',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.saleOrderStatus') },
                           { max: 100, message: this.$t('field.saleOrderStatus')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderStatus"
          v-model="saleOrderData.saleOrderStatus"
          :placeholder="'请输入'+ this.$t('field.saleOrderStatus')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.saleOrderStatusDesc')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderStatusDesc',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.saleOrderStatusDesc') },
                           { max: 100, message: this.$t('field.saleOrderStatusDesc')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderStatusDesc"
          v-model="saleOrderData.saleOrderStatusDesc"
          :placeholder="'请输入'+ this.$t('field.saleOrderStatusDesc')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.activityName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.activityName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.activityName') },
                           { max: 100, message: this.$t('field.activityName')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.activityName"
          v-model="saleOrderData.activityName"
          :placeholder="'请输入'+ this.$t('field.activityName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.saleOrderSubmitTime')"
        :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
        :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
      >
        {{ formatTime(saleOrderData.saleOrderSubmitTime) }}
      </a-form-item>
      <a-form-item
        :label="$t('field.memberNickName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.memberInfo.memberNickName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.memberNickName') },
                           { max: 100, message: this.$t('field.memberNickName')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.memberInfo.memberNickName"
          v-model="saleOrderData.memberInfo.memberNickName"
          :placeholder="'请输入'+ this.$t('field.memberNickName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.memberAvatarUrl')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.memberInfo.memberAvatarUrl',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.memberAvatarUrl') },
                           { max: 100, message: this.$t('field.memberAvatarUrl')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.memberInfo.memberAvatarUrl"
          v-model="saleOrderData.memberInfo.memberAvatarUrl"
          :placeholder="'请输入'+ this.$t('field.memberAvatarUrl')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.dealAmount')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.dealAmount',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.dealAmount') },
                           { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.dealAmount"
          v-model="saleOrderData.dealAmount"
          :placeholder="'请输入'+ this.$t('field.dealAmount')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.totalAmount')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.totalAmount',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.totalAmount') },
                           { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.totalAmount"
          v-model="saleOrderData.totalAmount"
          :placeholder="'请输入'+ this.$t('field.totalAmount')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.discountAmount')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.discountAmount',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.discountAmount') },
                           { max: 100, message: this.$t('field.discountAmount')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.discountAmount"
          v-model="saleOrderData.discountAmount"
          :placeholder="'请输入'+ this.$t('field.discountAmount')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.payAmount')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.payAmount',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.payAmount') },
                           { max: 100, message: this.$t('field.payAmount')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.payAmount"
          v-model="saleOrderData.payAmount"
          :placeholder="'请输入'+ this.$t('field.payAmount')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.shippingFee')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.shippingFee',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.shippingFee') },
                           { max: 100, message: this.$t('field.shippingFee')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.shippingFee"
          v-model="saleOrderData.shippingFee"
          :placeholder="'请输入'+ this.$t('field.shippingFee')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.dealAmountShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.dealAmountShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.dealAmountShow') },
                           { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.dealAmountShow"
          v-model="saleOrderData.dealAmountShow"
          :placeholder="'请输入'+ this.$t('field.dealAmountShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.totalAmountShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.totalAmountShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.totalAmountShow') },
                           { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.totalAmountShow"
          v-model="saleOrderData.totalAmountShow"
          :placeholder="'请输入'+ this.$t('field.totalAmountShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.discountAmountShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.discountAmountShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.discountAmountShow') },
                           { max: 100, message: this.$t('field.discountAmountShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.discountAmountShow"
          v-model="saleOrderData.discountAmountShow"
          :placeholder="'请输入'+ this.$t('field.discountAmountShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.payAmountShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.payAmountShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.payAmountShow') },
                           { max: 100, message: this.$t('field.payAmountShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.payAmountShow"
          v-model="saleOrderData.payAmountShow"
          :placeholder="'请输入'+ this.$t('field.payAmountShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.shippingFeeShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.shippingFeeShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.shippingFeeShow') },
                           { max: 100, message: this.$t('field.shippingFeeShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.shippingFeeShow"
          v-model="saleOrderData.shippingFeeShow"
          :placeholder="'请输入'+ this.$t('field.shippingFeeShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.totalQuantity')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.totalQuantity',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.totalQuantity') },
                           { max: 100, message: this.$t('field.totalQuantity')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.totalQuantity"
          v-model="saleOrderData.totalQuantity"
          :placeholder="'请输入'+ this.$t('field.totalQuantity')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.deliveryMethodType')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.deliveryMethodType',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.deliveryMethodType') },
                           { max: 100, message: this.$t('field.deliveryMethodType')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.deliveryMethodType"
          v-model="saleOrderData.deliveryMethodType"
          :placeholder="'请输入'+ this.$t('field.deliveryMethodType')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.deliveryMethodDesc')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.deliveryMethodDesc',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.deliveryMethodDesc') },
                           { max: 100, message: this.$t('field.deliveryMethodDesc')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.deliveryMethodDesc"
          v-model="saleOrderData.deliveryMethodDesc"
          :placeholder="'请输入'+ this.$t('field.deliveryMethodDesc')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.logisticsModel')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.logisticsModel',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.logisticsModel') },
                           { max: 100, message: this.$t('field.logisticsModel')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.logisticsModel"
          v-model="saleOrderData.logisticsModel"
          :placeholder="'请输入'+ this.$t('field.logisticsModel')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.logisticsModelDesc')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.logisticsModelDesc',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.logisticsModelDesc') },
                           { max: 100, message: this.$t('field.logisticsModelDesc')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.logisticsModelDesc"
          v-model="saleOrderData.logisticsModelDesc"
          :placeholder="'请输入'+ this.$t('field.logisticsModelDesc')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.payMethodType')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.payMethodType',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.payMethodType') },
                           { max: 100, message: this.$t('field.payMethodType')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.payMethodType"
          v-model="saleOrderData.payMethodType"
          :placeholder="'请输入'+ this.$t('field.payMethodType')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.payMethodTypeDesc')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.payMethodTypeDesc',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.payMethodTypeDesc') },
                           { max: 100, message: this.$t('field.payMethodTypeDesc')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.payMethodTypeDesc"
          v-model="saleOrderData.payMethodTypeDesc"
          :placeholder="'请输入'+ this.$t('field.payMethodTypeDesc')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.isSelfDelivery')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.isSelfDelivery',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.isSelfDelivery') },
                           { max: 100, message: this.$t('field.isSelfDelivery')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.isSelfDelivery"
          v-model="saleOrderData.isSelfDelivery"
          :placeholder="'请输入'+ this.$t('field.isSelfDelivery')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.pickupStockName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.pickupStockInfo.pickupStockName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.pickupStockName') },
                           { max: 100, message: this.$t('field.pickupStockName')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.pickupStockInfo.pickupStockName"
          v-model="saleOrderData.pickupStockInfo.pickupStockName"
          :placeholder="'请输入'+ this.$t('field.pickupStockName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.contactName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.pickupStockInfo.contactName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.contactName') },
                           { max: 100, message: this.$t('field.contactName')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.pickupStockInfo.contactName"
          v-model="saleOrderData.pickupStockInfo.contactName"
          :placeholder="'请输入'+ this.$t('field.contactName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.contactPhone')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.pickupStockInfo.contactPhone',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.contactPhone') },
                           { max: 100, message: this.$t('field.contactPhone')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.pickupStockInfo.contactPhone"
          v-model="saleOrderData.pickupStockInfo.contactPhone"
          :placeholder="'请输入'+ this.$t('field.contactPhone')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.fullAddress')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.pickupStockInfo.fullAddress',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.fullAddress') },
                           { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.pickupStockInfo.fullAddress"
          v-model="saleOrderData.pickupStockInfo.fullAddress"
          :placeholder="'请输入'+ this.$t('field.fullAddress')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.receiverName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.shippingAddressInfo.receiverName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.receiverName') },
                           { max: 100, message: this.$t('field.receiverName')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.shippingAddressInfo.receiverName"
          v-model="saleOrderData.shippingAddressInfo.receiverName"
          :placeholder="'请输入'+ this.$t('field.receiverName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.receiverPhone')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.shippingAddressInfo.receiverPhone',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.receiverPhone') },
                           { max: 100, message: this.$t('field.receiverPhone')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.shippingAddressInfo.receiverPhone"
          v-model="saleOrderData.shippingAddressInfo.receiverPhone"
          :placeholder="'请输入'+ this.$t('field.receiverPhone')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.fullAddress')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.shippingAddressInfo.fullAddress',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.fullAddress') },
                           { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.shippingAddressInfo.fullAddress"
          v-model="saleOrderData.shippingAddressInfo.fullAddress"
          :placeholder="'请输入'+ this.$t('field.fullAddress')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.skuNo')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.skuNo',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.skuNo') },
                           { max: 100, message: this.$t('field.skuNo')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.skuNo"
          v-model="saleOrderData.saleOrderItemList.skuNo"
          :placeholder="'请输入'+ this.$t('field.skuNo')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.skuImage')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.skuImage',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.skuImage') },
                           { max: 100, message: this.$t('field.skuImage')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.skuImage"
          v-model="saleOrderData.saleOrderItemList.skuImage"
          :placeholder="'请输入'+ this.$t('field.skuImage')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.skuName')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.skuName',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.skuName') },
                           { max: 100, message: this.$t('field.skuName')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.skuName"
          v-model="saleOrderData.saleOrderItemList.skuName"
          :placeholder="'请输入'+ this.$t('field.skuName')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.quantity')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.quantity',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.quantity') },
                           { max: 100, message: this.$t('field.quantity')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.quantity"
          v-model="saleOrderData.saleOrderItemList.quantity"
          :placeholder="'请输入'+ this.$t('field.quantity')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.tagPrice')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.tagPrice',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.tagPrice') },
                           { max: 100, message: this.$t('field.tagPrice')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.tagPrice"
          v-model="saleOrderData.saleOrderItemList.tagPrice"
          :placeholder="'请输入'+ this.$t('field.tagPrice')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.dealPrice')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.dealPrice',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.dealPrice') },
                           { max: 100, message: this.$t('field.dealPrice')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.dealPrice"
          v-model="saleOrderData.saleOrderItemList.dealPrice"
          :placeholder="'请输入'+ this.$t('field.dealPrice')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.totalAmount')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.totalAmount',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.totalAmount') },
                           { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.totalAmount"
          v-model="saleOrderData.saleOrderItemList.totalAmount"
          :placeholder="'请输入'+ this.$t('field.totalAmount')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.dealAmount')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.dealAmount',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.dealAmount') },
                           { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.dealAmount"
          v-model="saleOrderData.saleOrderItemList.dealAmount"
          :placeholder="'请输入'+ this.$t('field.dealAmount')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.tagPriceShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.tagPriceShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.tagPriceShow') },
                           { max: 100, message: this.$t('field.tagPriceShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.tagPriceShow"
          v-model="saleOrderData.saleOrderItemList.tagPriceShow"
          :placeholder="'请输入'+ this.$t('field.tagPriceShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.dealPriceShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.dealPriceShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.dealPriceShow') },
                           { max: 100, message: this.$t('field.dealPriceShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.dealPriceShow"
          v-model="saleOrderData.saleOrderItemList.dealPriceShow"
          :placeholder="'请输入'+ this.$t('field.dealPriceShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.totalAmountShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.totalAmountShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.totalAmountShow') },
                           { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.totalAmountShow"
          v-model="saleOrderData.saleOrderItemList.totalAmountShow"
          :placeholder="'请输入'+ this.$t('field.totalAmountShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.dealAmountShow')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.dealAmountShow',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.dealAmountShow') },
                           { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.dealAmountShow"
          v-model="saleOrderData.saleOrderItemList.dealAmountShow"
          :placeholder="'请输入'+ this.$t('field.dealAmountShow')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.unit')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-input
          v-decorator="[ 'saleOrderData.saleOrderItemList.unit',
                         {rules: [
                           { required: true, message: '请输入'+this.$t('field.unit') },
                           { max: 100, message: this.$t('field.unit')+'长度小于等于100' }]}
          ]"
          name="saleOrderData.saleOrderItemList.unit"
          v-model="saleOrderData.saleOrderItemList.unit"
          :placeholder="'请输入'+ this.$t('field.unit')"/>
      </a-form-item>
      <a-form-item
        :label="$t('field.tags')"
        :labelCol="{lg: {span: 7}, sm: {span: 7}}"
        :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
        <a-select mode="multiple" v-model="saleOrderData.saleOrderItemList.tags">
          <a-select-option
            v-for="(item, index) in codeList.tags"
            :key="index"
            :value="item.value">{{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

    </a-card>
  </div>
</template>

<script>
export default {
  name: 'EditSaleOrder',
  props: {
    saleOrderData: {
      type: Object
    }
  },
  data () {
    return {
      codeList: {
        'isSelfDelivery': []
      }
    }
  },
  methods: {}
}

</script>
<style>

</style>
