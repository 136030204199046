import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveSaleOrderData (parameter) {
  let url = baseUrl + '/order/saleOrder/save'
  if (!parameter.saleOrderId) {
    url = baseUrl + '/order/saleOrder/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchSaleOrder (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/search?' + qs.stringify(parameter, { indices: false }),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listSaleOrder (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}

export function approvalSaleOrder (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/approval',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}

export function getSaleOrderData (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableSaleOrder (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableSaleOrder (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function closeSaleOrder (parameter) {
  const url = baseUrl + '/order/saleOrder/close'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteSaleOrder (parameter) {
  const url = baseUrl + '/order/saleOrder/delete'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function getSaleOrderDetail (parameter) {
  console.log('parmas--', parameter)
  return request({
    url: baseUrl + '/order/saleOrder/getDetail', // getDetail
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function saveSaleOrderSaleOrderItemData (parameter) {
  let url = baseUrl + '/order/saleOrder/save'
  if (!parameter.saleOrderId) {
    url = baseUrl + '/order/saleOrder/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function exportSaleOrderExcel (parameter) {
  let url = baseUrl + '/order/saleOrder/exportExcel'
  if (parameter.type === 'wms') {
    url = baseUrl + '/order/saleOrder/exportWmsExcel'
  }
  return request({
    url: url,
    method: 'get',
    responseType: 'blob'
  }).then(res => {
    console.log('blob response', res)
    return res
  })
}

export function exportSaleOrderExcelMode () {
  return request({
    url: baseUrl + '/order/saleOrder/exportExcelMode',
    method: 'get',
    responseType: 'blob'
  }).then(res => {
    console.log('blob response', res)
    return res
  })
}

/**
export function importExcel (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/importExcelAndBackResult',
    method: 'post',
    data: parameter,
    responseType: 'blob'
  }).then(res => {
    console.log('response', res)
    return res
  })
}
*/

export function importExcel (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/importExcelAndBackMessage',
    method: 'post',
    data: parameter
  }).then(res => {
    console.log('response', res)
    return res.data
  })
}

export function getSaleOrderCount (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/countSaleOrder',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function importLogistics (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/importLogistics?' + qs.stringify(parameter, { indices: false }),
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function cancelSaleOrder (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/cancel',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function changeShippingAddress (parameter) {
  return request({
    url: baseUrl + '/order/saleOrder/changeShippingAddr',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
