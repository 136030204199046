<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <div style="margin-left:10%">

    <div>
      <div class="title">
        <p>订单号：</p>
        <span style="margin-left:3%">{{ saleOrderData.saleOrderNo }}</span>
      </div>
      <div class="title">
        <p>收件人：</p>
        <span style="margin-left:3%;">
          {{ saleOrderData.shippingAddress.contactFirstName }} {{ saleOrderData.shippingAddress.contactLastName }}
          {{ saleOrderData.shippingAddress.contactPhone }}
        </span>
      </div>

      <div class="title">
        <p>发货地址：</p>
        <span style="margin-left:3%">
          {{ saleOrderData.shippingAddress.room }} {{ saleOrderData.shippingAddress.house }} {{ saleOrderData.shippingAddress.street }}
          {{ saleOrderData.shippingAddress.city }} {{ saleOrderData.shippingAddress.state }} {{ saleOrderData.shippingAddress.country }}
        </span>

      </div>
      <br/>

    </div>

    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="20" :sm="24">
            <!-- <a-form-item
              :label="$t('field.shippingBillType')"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
              <a-select v-model="shipParam.shippingBillType" :placeholder="'请选择'+ this.$t('field.shippingBillType')">
                <a-select-option
                  v-for="(item, index) in codeList.shippingBillTypeList"
                  :key="index"
                  :value="item.value">{{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item> -->
            <a-form-item
              :label="$t('field.deliveryCompany')"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
              <a-select v-model="shipParam.deliveryCompanyCode" :placeholder="'请选择'+ this.$t('field.deliveryCompany')">
                <a-select-option
                  v-for="(item, index) in codeList.deliveryCompanyCodeList"
                  :key="index"
                  :value="item.value">{{ item.value }}-{{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              :label="$t('field.shippingBillNo')"
              :labelCol="{lg: {span: 7}, sm: {span: 7}}"
              :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
              <a-input
                name="shipParam.shippingBillNo"
                v-model="shipParam.shippingBillNo"
                :placeholder="'请输入'+ this.$t('field.shippingBillNo')"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div style="text-align: center">
      <a-button @click="$emit('handleShippingImportCancel')" >取消</a-button>
      <a-button style="margin-left: 10px" type="primary" @click="handleImportLogistics(saleOrderData)" >确定</a-button>
    </div>

  </div>

</template>

<script>
import {
  importLogistics
} from '@/api/order/saleOrder'
import { listDeliveryCompany } from '@/api/shipping/deliveryCompany'

export default {
  name: 'SaleOrderShippingImport',
  props: {
    saleOrderData: {
      type: Object
    },
    shipParam: {
      type: Object
    }
  },

  data () {
    return {
        // param:{
        //  logisticsModel:null,
        //  shippingBillNo:''
        // },
        codeList: {
        //  'shippingBillTypeList': [
        //    { label: '递四方', value: '递四方' },
        //    { label: 'DHL', value: 'DHL' },
        //    { label: 'USPS', value: 'USPS' },
        //    { label: 'FEDEX', value: 'FEDEX' },
        //    { label: '顺丰', value: '顺丰' },
        //    { label: 'EMS', value: 'EMS' }
        //   ]
        'deliveryCompanyCodeList': []
        }
    }
  },
  methods: {

      handleImportLogistics (record) {
        const that = this
        // if (this.shipParam.shippingBillType === null) {
        //   that.$message.warning('请选择物流类型！')
        //   return
        // }
        if (this.shipParam.deliveryCompanyCode === null) {
          that.$message.warning('请选择物流公司！')
          return
        }
        if (this.shipParam.shippingBillNo.trim() === '') {
          that.$message.warning('请输入运单编号！')
          return
        }
        const param = {
          saleOrderNo: record.saleOrderNo,
          shippingBillNo: this.shipParam.shippingBillNo,
          // shippingBillType: this.shipParam.shippingBillType
          deliveryCompanyCode: this.shipParam.deliveryCompanyCode
        }
        this.$confirm({
          title: '确认导入当前物流信息',
          // content: '物流公司类型[' + param.shippingBillType + '],物流单号[' + param.shippingBillNo + ']',
          content: '物流公司[' + param.deliveryCompanyCode + '],物流单号[' + param.shippingBillNo + ']',
          onOk () {
            console.log('OK')
            importLogistics(param).then((res) => {
              console.log('handleImportLogistics->importLogistics', param, res)
              if (res.success) {
                // that.$message.success("导入成功")
                that.$emit('handleShippingImportOk')
              } else {
                that.$message.error('导入失败,失败原因：' + res.respMsg)
              }
            }).catch(() => {
              that.$message.error('导入失败')
            })
          },
          onCancel () {
            console.log('Cancel')
          },
          class: 'test'
        })
      },

      getDeliveryCompanyList(){
        const that = this
        //获取快递公司列表
        listDeliveryCompany().then((res)=>{
          console.log("listDeliveryCompany",res)
          res.data.forEach(element => {
           // console.log("deliveryCompanyCodeList--element",element)
            that.codeList.deliveryCompanyCodeList.push({
              label:element.deliveryCompanyName,
              value:element.deliveryCompanyCode
            })
          })
          
        }).catch((res) => {
          // that.$message.error("获取物流公司信息失败")
        })
      }

  },

  mounted () {
    console.log('mounted')
    this.getDeliveryCompanyList()
  }

}

</script>
<style>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  margin-bottom: 14px;
}
</style>
