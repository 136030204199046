<template>
  <!-- show result info -->
  <div>

    <div>
      <div class="title">
        <p>总笔数：{{ backInfo.totCount }}，成功笔数：{{ backInfo.sucCount }}，失败笔数：{{ backInfo.failCount }}</p>
      </div>
      <a-divider style="margin-bottom: 12px"/>
      <a-table
        v-if="backInfo && backInfo.shippingFeedList"
        :columns="shippingFeedListColumns"
        :dataSource="backInfo.shippingFeedList"
        :bordered="true"
        :row-key="(record, index) => index"
        :scroll="{ y: 400,}"
        size="small"
        :pagination="false">
        <span slot="serial" slot-scope="text,record,index">
          {{ index + 1 }}
        </span>
        <div slot="message" slot-scope="text">
          <span>{{ text }}</span>
        </div>
      </a-table>

    </div>

    <a-divider style="margin-bottom: 12px"/>
    <div style="text-align: center">
      <a-button @click="$emit('handleShowResultInfoClose')" >{{ this.$t('button.close') }}</a-button>
      <a-button style="margin-left: 10px" type="primary" @click="handleSaveResultInfo(backInfo)" >{{ this.$t('button.save') }}</a-button>
    </div>

  </div>

</template>

<script>

export default {
  name: 'ResultInfo',
  props: {
    backInfo: {
      type: Object
    }

  },

  data () {
    return {
        codeList: {},
        shippingFeedListColumns: [
          {
            'defaultTitle': '序号',
            'title': this.$t('columnName.seqNo'),
            'dataIndex': 'seqNo',
            'key': 'seqNo',
            'align': 'center',
            width: '80px',
            scopedSlots: {
              customRender: 'serial'
            }
          },
          {
            'defaultTitle': '订单号',
            'title': this.$t('columnName.saleOrderNo'),
            'dataIndex': 'saleOrderNo',
            'key': 'saleOrderNo',
            'align': 'left'
          },
          {
            'defaultTitle': '物流公司类型',
            'title': this.$t('columnName.shippingBillType'),
            'dataIndex': 'shippingBillType',
            'key': 'shippingBillType',
            'align': 'left'
          },
          {
            'defaultTitle': '运单号',
            'title': this.$t('columnName.shippingBillNo'),
            'dataIndex': 'shippingBillNo',
            'key': 'shippingBillNo',
            'align': 'left'
          },
          {
            'defaultTitle': '结果',
            'title': this.$t('columnName.status'),
            'dataIndex': 'status',
            'key': 'status',
            'align': 'center',
            sorter: (a, b) => {
              console.log(a, b)
              return a.status == 'success' ? 1 : -1
          }
          },
          {
            'defaultTitle': '结果描述',
            'title': this.$t('columnName.statusDesc'),
            'dataIndex': 'statusDesc',
            'key': 'statusDesc',
            'align': 'left',
            scopedSlots: {
              customRender: 'message'
            }
          }
      ]
    }
  },
  methods: {
    handleSaveResultInfo (params) {
      // console.log("save result Info",params)
      var title = '总笔数：' + params.totCount + '，成功笔数：' + params.sucCount + '，失败笔数：' + params.failCount + '\r\n'
      var column = this.$t('columnName.seqNo') + '|' + this.$t('columnName.saleOrderNo') + '|' + this.$t('columnName.shippingBillType') + '|' + this.$t('columnName.shippingBillNo') + '|' + this.$t('columnName.status') + '|' + this.$t('columnName.statusDesc') + '\r\n'
      var str = ''
      params.shippingFeedList.forEach((item, index) => {
        str += index + 1 + '|' + item.saleOrderNo + '|' + item.shippingBillType + '|' + item.shippingBillNo + '|' + item.status + '|' + item.statusDesc + '|' + '\r\n'
      })
      var message = title + column + str
      // console.log('mesasage',message)
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(message)) // res.data.data是接口返回的数据
      element.setAttribute('download', 'result')// 文件名称
      element.style.display = 'none'
      element.click()
    }

  }
}

</script>
<style>
.title {
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  margin-bottom: 14px;
}
</style>
