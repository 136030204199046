<template>
  <div class="table-page-search-wrapper">
    <a-form layout="inline">
      <a-row :gutter="24">
        <a-col :md="6" :sm="24">
          <a-form-item
            :label="$t('field.saleOrderNo')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.saleOrderNo',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.saleOrderNo') },
                    { max: 100, message: this.$t('field.saleOrderNo') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.saleOrderNo"
              v-model="queryParam.saleOrderNo"
              :placeholder="'请输入' + this.$t('field.saleOrderNo')"
            />
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="24">
          <a-form-item
            :label="$t('field.contactPhone')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'queryParam.contactPhone',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.contactPhone') },
                    { max: 100, message: this.$t('field.contactPhone') + '长度小于等于100' }
                  ]
                }
              ]"
              name="queryParam.contactPhone"
              v-model.trim="queryParam.contactPhone"
              :placeholder="'请输入' + this.$t('field.contactPhone')"
            />
          </a-form-item>
        </a-col>

        <a-col :md="6" :sm="24">
          <a-form-item
            :label="$t('field.saleOrderStatus')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select
              :allowClear="true"
              mode="multiple"
              v-model="queryParam.saleOrderStatus"
              :placeholder="'请选择' + this.$t('field.saleOrderStatus')"
            >
              <a-select-option v-for="(item, index) in codeList.saleOrderStatus" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="24">
          <a-button icon="search" type="primary" @click="$parent.$parent.$refs.table.refresh(true)"
            >{{ $t('button.search') }}
          </a-button>
          <a-divider type="vertical" />
          <a-button
            style="margin-left: 8px"
            @click="
              () => {
                $parent.$parent.queryParam = {}
                $nextTick(() => {
                  $parent.$parent.$refs.table.refresh(true)
                })
              }
            "
            >{{ $t('button.reset') }}</a-button
          >
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :md="6" :sm="24">
          <a-form-item
            :label="$t('field.isSlaveOrder')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select
              :allowClear="true"
              v-model="queryParam.orderType"
              :placeholder="'请选择' + this.$t('field.isSlaveOrder')"
            >
              <a-select-option v-for="(item, index) in codeList.orderType" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
      </a-row>
      <a-row :gutter="24" v-if="needExport">
        <a-col :md="24" :sm="24">
          <a-button
            icon="export"
            style="margin-left: 20px"
            :loading="exportLoading"
            type="primary"
            @click="$emit('handleExport')"
            >{{ $t('button.exportLogic') }}
          </a-button>
          <a-divider type="vertical" />
          <!-- 停用wms导出
          <a-button
            icon="export"
            style="margin-left: 20px"
            :loading="exportWmsLoading"
            type="primary"
            @click="$emit('handleExport','wms')">{{ $t('button.exportWmsLogic') }}
          </a-button>
          <a-divider type="vertical"/>
          -->
          <a-button
            icon="export"
            style="margin-left: 8px"
            :loading="exportModeLoading"
            type="link"
            @click="$emit('handleExportMode')"
            >{{ $t('button.exportMode') }}
          </a-button>
          <a-divider type="vertical" />

          <a-upload
            :multiple="true"
            :fileList="downloadFiles"
            accept=".xls,.xlsx"
            :beforeUpload="beforeUpload"
            :customRequest="downloadFilesCustomRequest"
          >
            <a-button icon="import" style="margin-left: 8px" :loading="importLoading" type="primary">
              {{ $t('button.importLogic') }}
            </a-button>
          </a-upload>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col :md="24" :sm="24">
          <span class="table-page-search-submitButtons">
            <a-button size="large" style="margin-left: 20px" @click="qryByOrderStatus('待支付')"
              >待支付({{ totalNum.waitPaymentNum }})</a-button
            >
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 10px" @click="qryByOrderStatus('拼团中')"
              >拼团中({{ totalNum.inActivityNum }})</a-button
            >
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 10px" @click="qryByOrderStatus('待发货')"
              >待发货({{ totalNum.waitOutWareNum }})</a-button
            >
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 10px" @click="qryByOrderStatus('待收货')"
              >待团长收货({{ totalNum.waitLeaderRecvNum }})</a-button
            >
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 10px" @click="qryByOrderStatus('待分货')"
              >待团员收货({{ totalNum.waitActorRecvNum }})</a-button
            >
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 10px" @click="qryByOrderStatus('已完成')">已完成</a-button>
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 10px" @click="qryByOrderStatus('已取消')">已取消</a-button>
            <a-divider type="vertical" />
          </span>
        </a-col>
      </a-row>
    </a-form>

    <a-modal
      width="55%"
      title="导入结果信息展示"
      :visible="importResultInfoShow"
      footer=""
      @ok="handleShowResultInfoClose"
      @cancel="handleShowResultInfoClose"
    >
      <ResultInfo :backInfo="backInfo" @handleShowResultInfoClose="handleShowResultInfoClose" />
    </a-modal>
  </div>
</template>
<script>
import { importExcel } from '@/api/order/saleOrder'
import moment from 'moment'
import ResultInfo from '@/views/order/components/ResultInfo'

export default {
  name: 'SaleOrderSearch',
  importLoading: false,
  components: {
    ResultInfo
  },
  props: {
    // 查询参数
    queryParam: {
      type: Object
    },
    needExport: {
      //是否需要导入导出功能
      type: Boolean,
      default: true
    },
    exportLoading: {
      type: Boolean
    },
    exportWmsLoading: {
      type: Boolean
    },
    exportModeLoading: {
      type: Boolean
    },
    totalNum: {
      type: Object
    },
    showAdvancedSearchCondition: {
      type: Boolean
    }
  },
  data() {
    return {
      importLoading: false,
      codeList: {
        saleOrderStatus: [
          { label: '待支付', value: '待支付' },
          { label: '拼团中', value: '拼团中' },
          { label: '待发货', value: '待发货' },
          { label: '待团长收货', value: '待收货' },
          { label: '待团员收货', value: '待分货' },
          { label: '已完结', value: '已完成' },
          { label: '已取消', value: '已取消' }
        ],
        orderType: [
          { label: '直接购买', value: '直接购买' },
          { label: '团长订单', value: '团长订单' },
          { label: '团员订单', value: '团员订单' },
          { label: '裂变任务订单', value: '裂变任务订单' },
          { label: '裂变直接购买', value: '裂变直接购买' }
        ]
      },
      downloadFiles: [],
      importResultInfoShow: false,
      backInfo: {}
    }
  },
  created() {},
  mounted() {},
  methods: {
    toggleAdvancedSearch() {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    qryByOrderStatus(param) {
      const saleStatus = []
      saleStatus.push(param)
      this.queryParam.saleOrderStatus = saleStatus
      this.$parent.$parent.$refs.table.refresh(true)
    },
    beforeUpload(file) {
      console.log(file.type, file.size)
      const isExcel =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      if (!isExcel) {
        this.$message.error('请上传xls/xlsx格式文件')
      }
      const isLt10M = file.size / 1024 < 1024 * 1024 * 10 && file.size / 1024 > 1
      if (!isLt10M) {
        this.$message.error('文件大小应在1KB~10MB之间')
      }
      return isExcel && isLt10M
    },

    /**
    downloadFilesCustomRequest (data) {
      this.importLoading = true
      const formData = new FormData()
      if (data.file != null) {
        formData.append('file', data.file)
      }
      const that = this
      importExcel(formData).then((res) => {
        console.log('上传成功', res)
        that.$message.success('上传成功')
        console.log('上传成功，下载反馈文件')
        if (!res) {
          that.$message.error('反馈内容为空')
          that.importLoading = false
          return
        }
        that.$message.success('导入成功')
        const blob = new Blob([res])
        const fileName = '导入物流信息反馈.xlsx'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE以及IE内核的浏览器
          console.log('IE浏览器')
          navigator.msSaveBlob(blob, fileName)
        } else {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          // 释放URL对象所占资源
          window.URL.revokeObjectURL(url)
          // 用完即删
          document.body.removeChild(link)
          setTimeout(() => {
            that.importLoading = false
          }, 10000)
        }
      }).catch((res) => {
        console.log('上传失败', res)
        that.$message.error('上传失败')
        that.importLoading = false
      })
    },
    */

    downloadFilesCustomRequest(data) {
      this.importLoading = true
      const formData = new FormData()
      if (data.file != null) {
        formData.append('file', data.file)
      }
      const that = this
      importExcel(formData)
        .then(res => {
          console.log('导入完成', res)
          that.importLoading = false
          if (res.success) {
            that.$message.success('导入成功')
            that.backInfo = res
            that.importResultInfoShow = true
          } else {
            that.$message.error('导入失败,原因：' + res.respMsg)
          }
        })
        .catch(res => {
          console.log('导入失败', res)
          that.$message.error('导入失败')
          that.importLoading = false
        })
    },

    handleShowResultInfoClose() {
      this.importResultInfoShow = false
    }
  }
}
</script>
