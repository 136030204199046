import request from '@/utils/request'

const baseUrl = '/api'

export function batchCreateSkuData (parameter) {
  const url = baseUrl + '/goods/spu/batchCreateSku'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function saveSpuData (parameter) {
  let url = baseUrl + '/goods/spu/save'
  if (!parameter.spuId) {
    url = baseUrl + '/goods/spu/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchSpu (parameter) {
  return request({
    url: baseUrl + '/goods/spu/search',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listSpu (parameter) {
  return request({
    url: baseUrl + '/goods/spu/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function approvalSpu (parameter) {
  return request({
    url: baseUrl + '/goods/spu/approval',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getSpuData (parameter) {
  return request({
    url: baseUrl + '/goods/spu/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableSpu (parameter) {
  return request({
    url: baseUrl + '/goods/spu/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableSpu (parameter) {
  return request({
    url: baseUrl + '/goods/spu/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function getSpuDetail (parameter) {
  return request({
    url: baseUrl + '/goods/spu/get', // getDetail
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function saveSpuSaleAttrData (parameter) {
  let url = baseUrl + '/goods/spu/save'
  if (!parameter.spuId) {
    url = baseUrl + '/goods/spu/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteSpu (parameter) {
  return request({
    url: baseUrl + '/goods/spu/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
