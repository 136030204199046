<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-descriptions bordered title="">
        <a-descriptions-item label="订单编号">{{ data.saleOrderNo }}</a-descriptions-item>
        <a-descriptions-item label="订单状态">{{ data.saleOrderStatus }}</a-descriptions-item>
        <a-descriptions-item label="订单提交时间">{{ formatDateTime(data.saleOrderSubmitTime) }}</a-descriptions-item>
        <a-descriptions-item label="订单创建时间">{{ formatDateTime(data.createTime) }}</a-descriptions-item>
      </a-descriptions>
      <br />

      <a-descriptions v-if="data.memberInfo" bordered title="会员信息">
        <a-descriptions-item label="会员昵称">{{ data.memberInfo.memberNickName }}</a-descriptions-item>
        <a-descriptions-item label="会员ID">{{ data.memberInfo.memberId }}</a-descriptions-item>
      </a-descriptions>
      <br />

      <div v-if="data && data.activityOrderInfoList && data.activityOrderInfoList.length > 0" class="title">
        拼团关联订单
      </div>
      <a-table
        v-if="data && data.activityOrderInfoList && data.activityOrderInfoList.length > 0"
        :columns="activityOrderInfoListColumns"
        :dataSource="data.activityOrderInfoList"
        :row-key="(record, index) => index"
        :pagination="false"
      >
        <div slot="orderStyle" slot-scope="text">
          <span style="font-weight:bold" v-if="text === data.saleOrderNo">{{ text }}</span>
          <span v-if="text !== data.saleOrderNo">{{ text }}</span>
        </div>
        <template slot="action" slot-scope="text, record">
          <a v-if="record.saleOrderNo !== data.saleOrderNo" @click="handleDetail(record)">{{ $t('button.detail') }}</a>
        </template>
      </a-table>
      <br />

      <div v-if="data && data.saleOrderItemList" class="title">商品明细</div>
      <a-table
        v-if="data && data.saleOrderItemList"
        :columns="saleOrderItemListColumns"
        :dataSource="data.saleOrderItemList"
        :row-key="(record, index) => index"
        :bordered="true"
        :pagination="false"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="money" slot-scope="text">
          {{ formatTypeMoney(text, data.currentType, data.moneyMultiple) }}
        </div>
        <div slot="saleAttrList" slot-scope="text, record">
          <a-tag v-for="(item, idx) in record.saleAttrList" :key="idx" color="blue">
            [{{ item.attrName }}]-[{{ item.valueName }}] </a-tag
          ><br />
        </div>
        <template slot="action" slot-scope="text, record">
          <a @click="handleSaleOrderSkuEdit(record)">{{ $t('button.edit') }}</a>
        </template>
      </a-table>
      <br /><br />

      <a-descriptions title="">
        <a-descriptions-item label="商品原金额">{{
          formatTypeMoney(data.goodsTotalAmount, data.currentType, data.moneyMultiple)
        }}</a-descriptions-item>
        <a-descriptions-item label="商品优惠金额">{{
          formatTypeMoney(data.goodsDiscountAmount, data.currentType, data.moneyMultiple)
        }}</a-descriptions-item>
      </a-descriptions>

      <a-descriptions title="">
        <a-descriptions-item label="邮费金额">{{
          formatTypeMoney(data.shippingFeeTotalAmount, data.currentType, data.moneyMultiple)
        }}</a-descriptions-item>
        <a-descriptions-item label="邮费优惠金额">{{
          formatTypeMoney(data.shippingDiscountAmount, data.currentType, data.moneyMultiple)
        }}</a-descriptions-item>
      </a-descriptions>

      <div v-if="data && data.memberCouponList" class="title">优惠明细</div>
      <a-table
        v-if="data && data.memberCouponList"
        :columns="goodCouponListColumns"
        :dataSource="data.memberCouponList"
        :bordered="true"
        :row-key="(record, index) => index"
        size="small"
        :pagination="false"
      >
        <div slot="money" slot-scope="text">$ {{ formatMoney(text, 0, '.', ',') }}</div>
        <div slot="datetime" slot-scope="text">
          {{ formatDateTime(text) }}
        </div>
        <div slot="discount" slot-scope="text">
          <span>最低消费金额：{{ formatTypeMoney(text.minTotalAmount, data.currentType, data.moneyMultiple) }} </span>
          <br />
          <span>商品折扣：{{ text.pricePercent }}% </span> <br />
          <span>商品优惠金额：{{ formatTypeMoney(text.priceAmount, data.currentType, data.moneyMultiple) }} </span>
          <br />
          <span>运费折扣：{{ text.shippingFeeAmountPercent }}% </span> <br />
          <span
            >运费优惠金额：{{ formatTypeMoney(text.shippingFeeAmount, data.currentType, data.moneyMultiple) }}
          </span>
        </div>
      </a-table>
      <br />

      <div v-if="data" class="title">
        <p>
          实付金额:
          {{ formatTypeMoney(data.goodsDealAmount + data.shippingDealAmount, data.currentType, data.moneyMultiple) }}
        </p>
      </div>
      <br />

      <a-descriptions v-if="data" title="余额支付信息">
        <a-descriptions-item label="商品余额支付金额">{{
          formatTypeMoney(data.goodsBalanceAmount, data.currentType, data.moneyMultiple)
        }}</a-descriptions-item>
        <a-descriptions-item label="运费余额支付金额">{{
          formatTypeMoney(data.shippingBalanceAmount, data.currentType, data.moneyMultiple)
        }}</a-descriptions-item>
      </a-descriptions>
      <br />

      <div v-if="data && data.payBillList" class="title">渠道支付信息</div>
      <a-table
        v-if="data && data.payBillList"
        :columns="payBillListColumns"
        :dataSource="data.payBillList"
        :bordered="true"
        :row-key="(record, index) => index"
        :pagination="false"
      >
        <div slot="money" slot-scope="text">
          {{ formatTypeMoney(text, data.currentType, data.moneyMultiple) }}
        </div>
        <div slot="datetime" slot-scope="text">
          {{ formatDateTime(text) }}
        </div>
      </a-table>
      <br />

      <a-descriptions v-if="data.shippingAddress" bordered title="收货信息" :column="4">
        <a-descriptions-item label="联系人电话">{{ data.shippingAddress.contactPhone }}</a-descriptions-item>
        <a-descriptions-item label="姓名">{{
          data.shippingAddress.contactFirstName + ' ' + data.shippingAddress.contactLastName
        }}</a-descriptions-item>
        <a-descriptions-item></a-descriptions-item>
        <a-descriptions-item></a-descriptions-item>
        <a-descriptions-item label="城市">{{data.shippingAddress.city}}</a-descriptions-item>
        <a-descriptions-item label="州">{{data.shippingAddress.state}}</a-descriptions-item>
        <a-descriptions-item label="邮编">{{data.shippingAddress.postCode}}</a-descriptions-item>
        <a-descriptions-item label="国家">{{data.shippingAddress.country}}</a-descriptions-item>
        <a-descriptions-item label="地址">{{data.shippingAddress.address}}</a-descriptions-item>
      </a-descriptions>
      <br />
      <a-button htmlType="button" type="primary" @click="handleModifyReciveAddr(data)">修改收货地址</a-button>
      <br /><br />

      <a-descriptions v-if="data.packageList" title="包裹信息"> </a-descriptions>
      <div v-if="data.packageList.length == 0">
        <span style="margin-left:20px">暂无包裹信息</span>
      </div>

      <div v-for="(item, idx) in data.packageList" :key="idx">
        <div v-if="item" class="title">
          包裹{{ idx + 1 }} ( {{ item.shippingAddress == null ? '' : item.shippingAddress.contactFirstName }}
          {{ item.shippingAddress == null ? '' : item.shippingAddress.contactLastName }} ) - 编号:[{{
            item.customerShippingPackageNo
          }}] - {{ item.isSlaveOrder == true ? '团员包裹' : '团长包裹' }}
        </div>
        <a-descriptions v-if="item" title="">
          <a-descriptions-item label="订单号">{{ item.saleOrderNo }}</a-descriptions-item>
          <a-descriptions-item label="包裹状态">{{ item.packageStatusName }}</a-descriptions-item>
          <a-descriptions-item label="签收时间">{{
            item.shippingInfo && item.shippingInfo.signTime ? formatDateTime(item.shippingInfo.signTime) : ''
          }}</a-descriptions-item>
          <a-descriptions-item label="联系人"
            >{{ item.shippingAddress == null ? '' : item.shippingAddress.contactFirstName }}
            {{ item.shippingAddress == null ? '' : item.shippingAddress.contactLastName }}</a-descriptions-item
          >
          <a-descriptions-item label="联系电话">{{
            item.shippingAddress == null ? '' : item.shippingAddress.contactPhone
          }}</a-descriptions-item>
          <a-descriptions-item label="取件码">{{ item.verificationCode }}</a-descriptions-item>
        </a-descriptions>
        <a-table
          v-if="item && item.packageItemList"
          :columns="packageItemListColumns"
          :dataSource="item.packageItemList"
          :bordered="true"
          :row-key="(record, index) => index"
          :pagination="false"
        >
          <div slot="saleAttrList" slot-scope="text, record">
            <a-tag v-for="(item, idx) in record.saleAttrList" :key="idx" color="blue">
              [{{ item.attrName }}]-[{{ item.valueName }}] </a-tag
            ><br />
          </div>
        </a-table>
        <br />
      </div>
      <br />
      <a-descriptions bordered title="物流信息">
        <a-descriptions-item label="物流单号">{{ data.shippingBillNo }}</a-descriptions-item>
        <!-- <a-descriptions-item label="物流类型">{{ data.logisticsModel }}</a-descriptions-item> -->
        <a-descriptions-item label="物流公司"
          >{{ data.deliveryCompanyCode }} - {{ data.deliveryCompanyName }}</a-descriptions-item
        >
      </a-descriptions>

      <br />
      <a-button
        v-if="data.saleOrderStatus == '待发货' && data.isSlaveOrder != true"
        htmlType="button"
        type="primary"
        @click="handleImportShipping(data)"
        >导入运单信息</a-button
      >
      <a-button
        style="margin-left: 20px"
        htmlType="button"
        type="primary"
        @click="handleShowShippingInfo(data.saleOrderNo)"
        >查询物流信息</a-button
      >
      <br /><br />
      <a-modal
        width="60%"
        :title="$t('page.saleOrderShippingInfo')"
        :visible="saleOrderShippingInfoShow"
        @ok="handleShowShippingInfoOk"
        @cancel="handleShowShippingInfoOk"
      >
        <template>
          <div v-if="!data.shippingHistory || data.shippingHistory.length == 0">暂无物流信息</div>
          <div v-if="data.shippingHistory && data.shippingHistory.length > 0">
            <a-table
              :columns="shippingHistoryListColumns"
              :dataSource="data.shippingHistory"
              :bordered="true"
              :row-key="(record, index) => index"
              :pagination="false"
            >
              <div slot="datetime" slot-scope="text">
                {{ formatDateTime(text) }}
              </div>
            </a-table>
          </div>
        </template>
        <template slot="footer">
          <a-button type="primary" @click="handleShowShippingInfoOk">{{ $t('button.close') }}</a-button>
        </template>
      </a-modal>

      <div v-if="data.saleOrderStatus == '待发货'" style="text-align: center">
        <a-button htmlType="button" type="primary" @click="handleCancelOrder(data.saleOrderNo)">取消订单</a-button>
      </div>
      <a-modal
        width="35%"
        title="输入物流信息"
        :visible="saleOrderShippingImportShow"
        footer=""
        @ok="handleShippingImportOk"
        @cancel="handleShippingImportCancel"
      >
        <SaleOrderShippingImport
          :saleOrderData="saleOrderData"
          :shipParam="shipParam"
          @handleShippingImportOk="handleShippingImportOk"
          @handleShippingImportCancel="handleShippingImportCancel"
        />
      </a-modal>

      <a-modal
        width="35%"
        title="修改收货信息"
        :visible="saleOrderChangeAddressShow"
        footer=""
        @ok="handleModifyReciveAddrOk"
        @cancel="handleModifyReciveAddrCancel"
      >
        <SaleOrderChangeAddress
          :saleOrderData="saleOrderData"
          :addressInfo="addressInfo"
          @handleModifyReciveAddrOk="handleModifyReciveAddrOk"
          @handleModifyReciveAddrCancel="handleModifyReciveAddrCancel"
        />
      </a-modal>

      <!-- <a-modal
        :closable="true"
        :visible="outShowDetailDrawer"
        width="80%"
        footer=""
        @cancel="onOutDetailDrawerClose">
        <SaleOrderDetail ref="memberDetail" :options="outDetailOptions" :showDetailDrawer="outShowDetailDrawer"></SaleOrderDetail>
      </a-modal> -->
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import { getSaleOrderDetail, cancelSaleOrder } from '@/api/order/saleOrder'
import SaleOrderItem from '@/views/order/components/SaleOrderItem'
import SaleOrderShippingImport from '@/views/order/components/SaleOrderShippingImport'
import SaleOrderChangeAddress from '@/views/order/components/SaleOrderChangeAddress'
// import SaleOrderDetail from '@/views/order/SaleOrderDetail'

export default {
  name: 'SaleOrderDetail',
  components: {
    STable,
    SaleOrderItem,
    SaleOrderShippingImport,
    SaleOrderChangeAddress
    // SaleOrderDetail
  },
  props: {
    options: {
      type: Object
    },
    showDetailDrawer: {
      type: Boolean
    }
  },
  created() {
    // getRoleList({t: new Date()})
  },
  watch: {
    showDetailDrawer(val, oldVal) {
      console.log(val)
      if (val) {
        this.loadData({})
      }
    }
  },
  mounted() {
    this.loadData({})
  },
  data() {
    return {
      outShowDetailDrawer: false,
      outDetailOptions: {},
      saleOrderShippingInfoShow: false,
      data: {},
      shipParam: {},
      saleOrderShippingImportShow: false,
      saleOrderData: {},
      addressInfo: {},
      saleOrderChangeAddressShow: false,
      activityOrderInfoListColumns: [
        {
          defaultTitle: '订单号',
          title: this.$t('columnName.saleOrderNo'),
          dataIndex: 'saleOrderNo',
          key: 'saleOrderNo',
          align: 'left',
          scopedSlots: {
            customRender: 'orderStyle'
          }
        },
        {
          defaultTitle: '订单种类',
          title: this.$t('columnName.isSlaveOrder'),
          dataIndex: 'isSlaveOrder',
          key: 'isSlaveOrder',
          align: 'left',
          customRender: text => this.getCode('isSlaveOrder', text)
        },
        {
          defaultTitle: '操作',
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],

      saleOrderItemListColumns: [
        {
          defaultTitle: '商品ID',
          title: this.$t('columnName.remarkNo'),
          dataIndex: 'seq',
          key: 'seq',
          align: 'left',
          scopedSlots: {
            customRender: 'serial'
          }
        },
        {
          defaultTitle: 'SKU编号',
          title: this.$t('columnName.skuNo'),
          dataIndex: 'skuNo',
          key: 'skuNo',
          align: 'left'
        },
        {
          defaultTitle: 'SKU名称',
          title: this.$t('columnName.skuName'),
          dataIndex: 'skuName',
          key: 'skuName',
          align: 'left'
        },
        {
          defaultTitle: '销售属性列表',
          title: this.$t('columnName.saleAttrList'),
          dataIndex: 'saleAttrList',
          key: 'saleAttrList',
          align: 'left',
          scopedSlots: {
            customRender: 'saleAttrList'
          }
        },
        {
          defaultTitle: '数量',
          title: this.$t('columnName.quantity'),
          dataIndex: 'quantity',
          key: 'quantity',
          align: 'right'
        },
        {
          defaultTitle: '交易价格',
          title: this.$t('columnName.dealPrice'),
          dataIndex: 'dealPrice',
          key: 'dealPrice',
          align: 'right',
          scopedSlots: {
            customRender: 'money'
          }
        }
        /**
        {
          'defaultTitle': '操作',
          'title': '操作',
          'dataIndex': 'action',
          'key': 'action',
          'align': 'left',
          'scopedSlots': {
            'customRender': 'action'
          }
        } */
      ],

      payBillListColumns: [
        {
          defaultTitle: '支付金额',
          title: this.$t('columnName.payAmount'),
          dataIndex: 'payAmount',
          key: 'payAmount',
          align: 'left',
          scopedSlots: {
            customRender: 'money'
          }
        },

        {
          defaultTitle: '支付渠道',
          title: this.$t('columnName.channel'),
          dataIndex: 'channelName',
          key: 'channelName',
          align: 'left'
        },
        {
          defaultTitle: '支付业务类型',
          title: this.$t('columnName.payBizType'),
          dataIndex: 'payBizTypeName',
          key: 'payBizTypeName',
          align: 'center'
        },
  
        {
          defaultTitle: '支付流水',
          title: this.$t('columnName.payBillNo'),
          dataIndex: 'payBillNo',
          key: 'payBillNo',
          align: 'left'
        },
        {
          defaultTitle: '支付状态',
          title: this.$t('columnName.payBillStatus'),
          dataIndex: 'payBillStatusName',
          key: 'payBillStatusName',
          align: 'left'
        },
        {
          defaultTitle: '支付时间',
          title: this.$t('columnName.paidTime'),
          dataIndex: 'paidTime',
          key: 'paidTime',
          align: 'left',
          scopedSlots: {
            customRender: 'datetime'
          }
        }
      ],

      packageItemListColumns: [
        {
          defaultTitle: 'SKU编号',
          title: this.$t('columnName.skuNo'),
          dataIndex: 'skuNo',
          key: 'skuNo',
          align: 'left'
        },
        {
          defaultTitle: 'SKU名称',
          title: this.$t('columnName.skuName'),
          dataIndex: 'skuName',
          key: 'skuName',
          align: 'left'
        },
        {
          defaultTitle: '销售属性列表',
          title: this.$t('columnName.saleAttrList'),
          dataIndex: 'saleAttrList',
          key: 'saleAttrList',
          align: 'left',
          scopedSlots: {
            customRender: 'saleAttrList'
          }
        },
        {
          defaultTitle: '数量',
          title: this.$t('columnName.quantity'),
          dataIndex: 'quantity',
          key: 'quantity',
          align: 'right'
        }
      ],
      goodCouponListColumns: [
        {
          defaultTitle: '券名称',
          title: this.$t('columnName.couponName'),
          dataIndex: 'couponName',
          key: 'couponName',
          align: 'left'
        },
        {
          defaultTitle: '促销条件类型',
          title: this.$t('columnName.marketingDiscountType'),
          dataIndex: 'marketingDiscountType',
          key: 'marketingDiscountType',
          align: 'left'
        },
        {
          defaultTitle: '折扣信息',
          title: this.$t('columnName.discount'),
          dataIndex: 'discount',
          key: 'discount',
          align: 'left',
          scopedSlots: {
            customRender: 'discount'
          }
        },
        {
          defaultTitle: '券码',
          title: this.$t('columnName.couponCode'),
          dataIndex: 'memberCouponId',
          key: 'memberCouponId',
          align: 'left'
        }
      ],
      shippingHistoryListColumns: [
        {
          defaultTitle: '更新时间',
          title: this.$t('columnName.updateTime'),
          dataIndex: 'updateTime',
          key: 'updateTime',
          align: 'left',
          scopedSlots: {
            customRender: 'datetime'
          },
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            console.log(a, b)
            return a.updateTime > b.updateTime ? 1 : -1
          }
        },
        {
          defaultTitle: '物流阶段',
          title: this.$t('columnName.shippingStage'),
          dataIndex: 'shippingStage',
          key: 'shippingStage',
          align: 'left'
        }
      ],

      saleOrderItemListVisible: false,
      saleOrderItemListConfirmLoading: false,
      saleOrderItemData: {}
    }
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      getSaleOrderDetail(
        Object.assign(parameter, {
          // saleOrderId: this.options.saleOrderId,
          saleOrderNo: this.options.saleOrderNo
        })
      ).then(res => {
        console.log('loadData->getSaleOrderDetail.parameter', parameter, res)
        this.data = res // this.data = res.data
        return this.data
      })
    },
    handleDetail(record) {
      let { saleOrderId, saleOrderNo } = record
      let { href } = this.$router.resolve({
        path: '/order/SaleOrderList',
        query: { saleOrderId, saleOrderNo }
      })
      window.open(href, '_blank')
      // this.outDetailOptions = {
      //   saleOrderId: record.saleOrderId,
      //   saleOrderNo: record.saleOrderNo
      // }
      // this.outShowDetailDrawer = true
    },
    handleSaleOrderSkuEdit(record) {
      console.log('edit sku record', record)
      this.$message.success('功能暂未实现')
    },
    handleCancelOrder(params) {
      const that = this
      console.log('cancel order record', params)
      const reqParam = {
        saleOrderNo: params
      }
      this.$confirm({
        title: '确认取消当前订单?',
        // content: this.$t('message.information'),
        onOk() {
          console.log('OK')
          that.handleSaleOrderCancel(reqParam)
        },
        onCancel() {
          console.log('Cancel')
        },
        class: 'test'
      })
    },

    handleSaleOrderCancel(reqParam) {
      const that = this
      return cancelSaleOrder(reqParam)
        .then(res => {
          console.log('cancelSaleOrder', reqParam, res)
          if (res.success) {
            that.loadData({})
            that.$message.success('提交成功')
          } else {
            that.$message.error('提交失败,原因：' + res.respMsg)
          }
        })
        .catch(() => {
          that.$message.success('提交失败')
        })
    },

    handleShowShippingInfo(params) {
      console.log('handleShowShippingInfo', params)
      this.saleOrderShippingInfoShow = true
    },
    handleShowShippingInfoOk(record) {
      console.log('handleShowShippingInfoOk', record)
      this.saleOrderShippingInfoShow = false
    },

    handleModifyReciveAddr(record) {
      console.log('handleModifyReciveAddr record', record)
      this.saleOrderData = record
      this.addressInfo = record.shippingAddress
      this.saleOrderChangeAddressShow = true
    },

    handleModifyReciveAddrOk(record) {
      const that = this
      console.log('handleModifyReciveAddrOk record', record)
      that.saleOrderChangeAddressShow = false
      that.$message.success('修改成功')
      that.loadData({})
    },

    handleModifyReciveAddrCancel(record) {
      console.log('handleModifyReciveAddrCancel record', record)
      const that = this
      that.saleOrderChangeAddressShow = false
    },

    handleImportShipping(record) {
      console.log('handleImportShipping record', record)
      this.saleOrderData = record
      this.shipParam = {
        logisticsModel: null,
        shippingBillNo: ''
      }
      this.saleOrderShippingImportShow = true
    },
    handleShippingImportOk(record) {
      const that = this
      that.saleOrderShippingImportShow = false
      that.$message.success('导入成功')
      that.loadData({})
    },

    handleShippingImportCancel(record) {
      const that = this
      that.saleOrderShippingImportShow = false
    },

    onOutDetailDrawerClose() {
      // this.$refs.table.refresh(true)
      this.outShowDetailDrawer = false
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        processing: '进行中',
        success: '完成',
        failed: '失败'
      }
      return statusMap[status]
    }
  },
  computed: {
    title() {
      return this.$route.meta.title
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
