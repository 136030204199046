var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{attrs:{"body-style":{padding: '24px 32px'},"bordered":false}},[_c('a-form-item',{attrs:{"label":_vm.$t('field.saleOrderNo'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderNo',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.saleOrderNo') },
                         { max: 100, message: this.$t('field.saleOrderNo')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderNo',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.saleOrderNo') },\n                         { max: 100, message: this.$t('field.saleOrderNo')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderNo","placeholder":'请输入'+ this.$t('field.saleOrderNo')},model:{value:(_vm.saleOrderData.saleOrderNo),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "saleOrderNo", $$v)},expression:"saleOrderData.saleOrderNo"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.shippingBillNo'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.shippingBillNo',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.shippingBillNo') },
                         { max: 100, message: this.$t('field.shippingBillNo')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.shippingBillNo',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.shippingBillNo') },\n                         { max: 100, message: this.$t('field.shippingBillNo')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.shippingBillNo","placeholder":'请输入'+ this.$t('field.shippingBillNo')},model:{value:(_vm.saleOrderData.shippingBillNo),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "shippingBillNo", $$v)},expression:"saleOrderData.shippingBillNo"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.saleOrderStatus'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderStatus',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.saleOrderStatus') },
                         { max: 100, message: this.$t('field.saleOrderStatus')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderStatus',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.saleOrderStatus') },\n                         { max: 100, message: this.$t('field.saleOrderStatus')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderStatus","placeholder":'请输入'+ this.$t('field.saleOrderStatus')},model:{value:(_vm.saleOrderData.saleOrderStatus),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "saleOrderStatus", $$v)},expression:"saleOrderData.saleOrderStatus"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.saleOrderStatusDesc'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderStatusDesc',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.saleOrderStatusDesc') },
                         { max: 100, message: this.$t('field.saleOrderStatusDesc')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderStatusDesc',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.saleOrderStatusDesc') },\n                         { max: 100, message: this.$t('field.saleOrderStatusDesc')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderStatusDesc","placeholder":'请输入'+ this.$t('field.saleOrderStatusDesc')},model:{value:(_vm.saleOrderData.saleOrderStatusDesc),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "saleOrderStatusDesc", $$v)},expression:"saleOrderData.saleOrderStatusDesc"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.activityName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.activityName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.activityName') },
                         { max: 100, message: this.$t('field.activityName')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.activityName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.activityName') },\n                         { max: 100, message: this.$t('field.activityName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.activityName","placeholder":'请输入'+ this.$t('field.activityName')},model:{value:(_vm.saleOrderData.activityName),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "activityName", $$v)},expression:"saleOrderData.activityName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.saleOrderSubmitTime'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_vm._v(" "+_vm._s(_vm.formatTime(_vm.saleOrderData.saleOrderSubmitTime))+" ")]),_c('a-form-item',{attrs:{"label":_vm.$t('field.memberNickName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.memberInfo.memberNickName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.memberNickName') },
                         { max: 100, message: this.$t('field.memberNickName')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.memberInfo.memberNickName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.memberNickName') },\n                         { max: 100, message: this.$t('field.memberNickName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.memberInfo.memberNickName","placeholder":'请输入'+ this.$t('field.memberNickName')},model:{value:(_vm.saleOrderData.memberInfo.memberNickName),callback:function ($$v) {_vm.$set(_vm.saleOrderData.memberInfo, "memberNickName", $$v)},expression:"saleOrderData.memberInfo.memberNickName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.memberAvatarUrl'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.memberInfo.memberAvatarUrl',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.memberAvatarUrl') },
                         { max: 100, message: this.$t('field.memberAvatarUrl')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.memberInfo.memberAvatarUrl',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.memberAvatarUrl') },\n                         { max: 100, message: this.$t('field.memberAvatarUrl')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.memberInfo.memberAvatarUrl","placeholder":'请输入'+ this.$t('field.memberAvatarUrl')},model:{value:(_vm.saleOrderData.memberInfo.memberAvatarUrl),callback:function ($$v) {_vm.$set(_vm.saleOrderData.memberInfo, "memberAvatarUrl", $$v)},expression:"saleOrderData.memberInfo.memberAvatarUrl"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.dealAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealAmount') },
                         { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.dealAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealAmount') },\n                         { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.dealAmount","placeholder":'请输入'+ this.$t('field.dealAmount')},model:{value:(_vm.saleOrderData.dealAmount),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "dealAmount", $$v)},expression:"saleOrderData.dealAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.totalAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalAmount') },
                         { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.totalAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalAmount') },\n                         { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.totalAmount","placeholder":'请输入'+ this.$t('field.totalAmount')},model:{value:(_vm.saleOrderData.totalAmount),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "totalAmount", $$v)},expression:"saleOrderData.totalAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.discountAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.discountAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.discountAmount') },
                         { max: 100, message: this.$t('field.discountAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.discountAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.discountAmount') },\n                         { max: 100, message: this.$t('field.discountAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.discountAmount","placeholder":'请输入'+ this.$t('field.discountAmount')},model:{value:(_vm.saleOrderData.discountAmount),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "discountAmount", $$v)},expression:"saleOrderData.discountAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.payAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.payAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.payAmount') },
                         { max: 100, message: this.$t('field.payAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.payAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.payAmount') },\n                         { max: 100, message: this.$t('field.payAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.payAmount","placeholder":'请输入'+ this.$t('field.payAmount')},model:{value:(_vm.saleOrderData.payAmount),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "payAmount", $$v)},expression:"saleOrderData.payAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.shippingFee'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.shippingFee',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.shippingFee') },
                         { max: 100, message: this.$t('field.shippingFee')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.shippingFee',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.shippingFee') },\n                         { max: 100, message: this.$t('field.shippingFee')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.shippingFee","placeholder":'请输入'+ this.$t('field.shippingFee')},model:{value:(_vm.saleOrderData.shippingFee),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "shippingFee", $$v)},expression:"saleOrderData.shippingFee"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.dealAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealAmountShow') },
                         { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.dealAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealAmountShow') },\n                         { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.dealAmountShow","placeholder":'请输入'+ this.$t('field.dealAmountShow')},model:{value:(_vm.saleOrderData.dealAmountShow),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "dealAmountShow", $$v)},expression:"saleOrderData.dealAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.totalAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalAmountShow') },
                         { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.totalAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalAmountShow') },\n                         { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.totalAmountShow","placeholder":'请输入'+ this.$t('field.totalAmountShow')},model:{value:(_vm.saleOrderData.totalAmountShow),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "totalAmountShow", $$v)},expression:"saleOrderData.totalAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.discountAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.discountAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.discountAmountShow') },
                         { max: 100, message: this.$t('field.discountAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.discountAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.discountAmountShow') },\n                         { max: 100, message: this.$t('field.discountAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.discountAmountShow","placeholder":'请输入'+ this.$t('field.discountAmountShow')},model:{value:(_vm.saleOrderData.discountAmountShow),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "discountAmountShow", $$v)},expression:"saleOrderData.discountAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.payAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.payAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.payAmountShow') },
                         { max: 100, message: this.$t('field.payAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.payAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.payAmountShow') },\n                         { max: 100, message: this.$t('field.payAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.payAmountShow","placeholder":'请输入'+ this.$t('field.payAmountShow')},model:{value:(_vm.saleOrderData.payAmountShow),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "payAmountShow", $$v)},expression:"saleOrderData.payAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.shippingFeeShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.shippingFeeShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.shippingFeeShow') },
                         { max: 100, message: this.$t('field.shippingFeeShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.shippingFeeShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.shippingFeeShow') },\n                         { max: 100, message: this.$t('field.shippingFeeShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.shippingFeeShow","placeholder":'请输入'+ this.$t('field.shippingFeeShow')},model:{value:(_vm.saleOrderData.shippingFeeShow),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "shippingFeeShow", $$v)},expression:"saleOrderData.shippingFeeShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalQuantity'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.totalQuantity',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalQuantity') },
                         { max: 100, message: this.$t('field.totalQuantity')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.totalQuantity',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalQuantity') },\n                         { max: 100, message: this.$t('field.totalQuantity')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.totalQuantity","placeholder":'请输入'+ this.$t('field.totalQuantity')},model:{value:(_vm.saleOrderData.totalQuantity),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "totalQuantity", $$v)},expression:"saleOrderData.totalQuantity"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.deliveryMethodType'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.deliveryMethodType',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.deliveryMethodType') },
                         { max: 100, message: this.$t('field.deliveryMethodType')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.deliveryMethodType',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.deliveryMethodType') },\n                         { max: 100, message: this.$t('field.deliveryMethodType')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.deliveryMethodType","placeholder":'请输入'+ this.$t('field.deliveryMethodType')},model:{value:(_vm.saleOrderData.deliveryMethodType),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "deliveryMethodType", $$v)},expression:"saleOrderData.deliveryMethodType"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.deliveryMethodDesc'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.deliveryMethodDesc',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.deliveryMethodDesc') },
                         { max: 100, message: this.$t('field.deliveryMethodDesc')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.deliveryMethodDesc',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.deliveryMethodDesc') },\n                         { max: 100, message: this.$t('field.deliveryMethodDesc')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.deliveryMethodDesc","placeholder":'请输入'+ this.$t('field.deliveryMethodDesc')},model:{value:(_vm.saleOrderData.deliveryMethodDesc),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "deliveryMethodDesc", $$v)},expression:"saleOrderData.deliveryMethodDesc"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.logisticsModel'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.logisticsModel',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.logisticsModel') },
                         { max: 100, message: this.$t('field.logisticsModel')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.logisticsModel',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.logisticsModel') },\n                         { max: 100, message: this.$t('field.logisticsModel')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.logisticsModel","placeholder":'请输入'+ this.$t('field.logisticsModel')},model:{value:(_vm.saleOrderData.logisticsModel),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "logisticsModel", $$v)},expression:"saleOrderData.logisticsModel"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.logisticsModelDesc'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.logisticsModelDesc',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.logisticsModelDesc') },
                         { max: 100, message: this.$t('field.logisticsModelDesc')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.logisticsModelDesc',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.logisticsModelDesc') },\n                         { max: 100, message: this.$t('field.logisticsModelDesc')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.logisticsModelDesc","placeholder":'请输入'+ this.$t('field.logisticsModelDesc')},model:{value:(_vm.saleOrderData.logisticsModelDesc),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "logisticsModelDesc", $$v)},expression:"saleOrderData.logisticsModelDesc"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.payMethodType'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.payMethodType',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.payMethodType') },
                         { max: 100, message: this.$t('field.payMethodType')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.payMethodType',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.payMethodType') },\n                         { max: 100, message: this.$t('field.payMethodType')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.payMethodType","placeholder":'请输入'+ this.$t('field.payMethodType')},model:{value:(_vm.saleOrderData.payMethodType),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "payMethodType", $$v)},expression:"saleOrderData.payMethodType"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.payMethodTypeDesc'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.payMethodTypeDesc',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.payMethodTypeDesc') },
                         { max: 100, message: this.$t('field.payMethodTypeDesc')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.payMethodTypeDesc',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.payMethodTypeDesc') },\n                         { max: 100, message: this.$t('field.payMethodTypeDesc')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.payMethodTypeDesc","placeholder":'请输入'+ this.$t('field.payMethodTypeDesc')},model:{value:(_vm.saleOrderData.payMethodTypeDesc),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "payMethodTypeDesc", $$v)},expression:"saleOrderData.payMethodTypeDesc"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.isSelfDelivery'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.isSelfDelivery',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.isSelfDelivery') },
                         { max: 100, message: this.$t('field.isSelfDelivery')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.isSelfDelivery',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.isSelfDelivery') },\n                         { max: 100, message: this.$t('field.isSelfDelivery')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.isSelfDelivery","placeholder":'请输入'+ this.$t('field.isSelfDelivery')},model:{value:(_vm.saleOrderData.isSelfDelivery),callback:function ($$v) {_vm.$set(_vm.saleOrderData, "isSelfDelivery", $$v)},expression:"saleOrderData.isSelfDelivery"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.pickupStockName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.pickupStockInfo.pickupStockName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.pickupStockName') },
                         { max: 100, message: this.$t('field.pickupStockName')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.pickupStockInfo.pickupStockName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.pickupStockName') },\n                         { max: 100, message: this.$t('field.pickupStockName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.pickupStockInfo.pickupStockName","placeholder":'请输入'+ this.$t('field.pickupStockName')},model:{value:(_vm.saleOrderData.pickupStockInfo.pickupStockName),callback:function ($$v) {_vm.$set(_vm.saleOrderData.pickupStockInfo, "pickupStockName", $$v)},expression:"saleOrderData.pickupStockInfo.pickupStockName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.contactName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.pickupStockInfo.contactName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.contactName') },
                         { max: 100, message: this.$t('field.contactName')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.pickupStockInfo.contactName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.contactName') },\n                         { max: 100, message: this.$t('field.contactName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.pickupStockInfo.contactName","placeholder":'请输入'+ this.$t('field.contactName')},model:{value:(_vm.saleOrderData.pickupStockInfo.contactName),callback:function ($$v) {_vm.$set(_vm.saleOrderData.pickupStockInfo, "contactName", $$v)},expression:"saleOrderData.pickupStockInfo.contactName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.contactPhone'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.pickupStockInfo.contactPhone',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.contactPhone') },
                         { max: 100, message: this.$t('field.contactPhone')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.pickupStockInfo.contactPhone',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.contactPhone') },\n                         { max: 100, message: this.$t('field.contactPhone')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.pickupStockInfo.contactPhone","placeholder":'请输入'+ this.$t('field.contactPhone')},model:{value:(_vm.saleOrderData.pickupStockInfo.contactPhone),callback:function ($$v) {_vm.$set(_vm.saleOrderData.pickupStockInfo, "contactPhone", $$v)},expression:"saleOrderData.pickupStockInfo.contactPhone"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.fullAddress'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.pickupStockInfo.fullAddress',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.fullAddress') },
                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.pickupStockInfo.fullAddress',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.fullAddress') },\n                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.pickupStockInfo.fullAddress","placeholder":'请输入'+ this.$t('field.fullAddress')},model:{value:(_vm.saleOrderData.pickupStockInfo.fullAddress),callback:function ($$v) {_vm.$set(_vm.saleOrderData.pickupStockInfo, "fullAddress", $$v)},expression:"saleOrderData.pickupStockInfo.fullAddress"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.receiverName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.shippingAddressInfo.receiverName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.receiverName') },
                         { max: 100, message: this.$t('field.receiverName')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.shippingAddressInfo.receiverName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.receiverName') },\n                         { max: 100, message: this.$t('field.receiverName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.shippingAddressInfo.receiverName","placeholder":'请输入'+ this.$t('field.receiverName')},model:{value:(_vm.saleOrderData.shippingAddressInfo.receiverName),callback:function ($$v) {_vm.$set(_vm.saleOrderData.shippingAddressInfo, "receiverName", $$v)},expression:"saleOrderData.shippingAddressInfo.receiverName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.receiverPhone'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.shippingAddressInfo.receiverPhone',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.receiverPhone') },
                         { max: 100, message: this.$t('field.receiverPhone')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.shippingAddressInfo.receiverPhone',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.receiverPhone') },\n                         { max: 100, message: this.$t('field.receiverPhone')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.shippingAddressInfo.receiverPhone","placeholder":'请输入'+ this.$t('field.receiverPhone')},model:{value:(_vm.saleOrderData.shippingAddressInfo.receiverPhone),callback:function ($$v) {_vm.$set(_vm.saleOrderData.shippingAddressInfo, "receiverPhone", $$v)},expression:"saleOrderData.shippingAddressInfo.receiverPhone"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.fullAddress'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.shippingAddressInfo.fullAddress',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.fullAddress') },
                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.shippingAddressInfo.fullAddress',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.fullAddress') },\n                         { max: 100, message: this.$t('field.fullAddress')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.shippingAddressInfo.fullAddress","placeholder":'请输入'+ this.$t('field.fullAddress')},model:{value:(_vm.saleOrderData.shippingAddressInfo.fullAddress),callback:function ($$v) {_vm.$set(_vm.saleOrderData.shippingAddressInfo, "fullAddress", $$v)},expression:"saleOrderData.shippingAddressInfo.fullAddress"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.skuNo'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.skuNo',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.skuNo') },
                         { max: 100, message: this.$t('field.skuNo')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.skuNo',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.skuNo') },\n                         { max: 100, message: this.$t('field.skuNo')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.skuNo","placeholder":'请输入'+ this.$t('field.skuNo')},model:{value:(_vm.saleOrderData.saleOrderItemList.skuNo),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "skuNo", $$v)},expression:"saleOrderData.saleOrderItemList.skuNo"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.skuImage'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.skuImage',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.skuImage') },
                         { max: 100, message: this.$t('field.skuImage')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.skuImage',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.skuImage') },\n                         { max: 100, message: this.$t('field.skuImage')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.skuImage","placeholder":'请输入'+ this.$t('field.skuImage')},model:{value:(_vm.saleOrderData.saleOrderItemList.skuImage),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "skuImage", $$v)},expression:"saleOrderData.saleOrderItemList.skuImage"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.skuName'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.skuName',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.skuName') },
                         { max: 100, message: this.$t('field.skuName')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.skuName',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.skuName') },\n                         { max: 100, message: this.$t('field.skuName')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.skuName","placeholder":'请输入'+ this.$t('field.skuName')},model:{value:(_vm.saleOrderData.saleOrderItemList.skuName),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "skuName", $$v)},expression:"saleOrderData.saleOrderItemList.skuName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.quantity'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.quantity',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.quantity') },
                         { max: 100, message: this.$t('field.quantity')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.quantity',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.quantity') },\n                         { max: 100, message: this.$t('field.quantity')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.quantity","placeholder":'请输入'+ this.$t('field.quantity')},model:{value:(_vm.saleOrderData.saleOrderItemList.quantity),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "quantity", $$v)},expression:"saleOrderData.saleOrderItemList.quantity"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.tagPrice'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.tagPrice',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.tagPrice') },
                         { max: 100, message: this.$t('field.tagPrice')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.tagPrice',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.tagPrice') },\n                         { max: 100, message: this.$t('field.tagPrice')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.tagPrice","placeholder":'请输入'+ this.$t('field.tagPrice')},model:{value:(_vm.saleOrderData.saleOrderItemList.tagPrice),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "tagPrice", $$v)},expression:"saleOrderData.saleOrderItemList.tagPrice"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealPrice'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.dealPrice',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealPrice') },
                         { max: 100, message: this.$t('field.dealPrice')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.dealPrice',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealPrice') },\n                         { max: 100, message: this.$t('field.dealPrice')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.dealPrice","placeholder":'请输入'+ this.$t('field.dealPrice')},model:{value:(_vm.saleOrderData.saleOrderItemList.dealPrice),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "dealPrice", $$v)},expression:"saleOrderData.saleOrderItemList.dealPrice"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.totalAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalAmount') },
                         { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.totalAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalAmount') },\n                         { max: 100, message: this.$t('field.totalAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.totalAmount","placeholder":'请输入'+ this.$t('field.totalAmount')},model:{value:(_vm.saleOrderData.saleOrderItemList.totalAmount),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "totalAmount", $$v)},expression:"saleOrderData.saleOrderItemList.totalAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealAmount'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.dealAmount',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealAmount') },
                         { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.dealAmount',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealAmount') },\n                         { max: 100, message: this.$t('field.dealAmount')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.dealAmount","placeholder":'请输入'+ this.$t('field.dealAmount')},model:{value:(_vm.saleOrderData.saleOrderItemList.dealAmount),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "dealAmount", $$v)},expression:"saleOrderData.saleOrderItemList.dealAmount"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.tagPriceShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.tagPriceShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.tagPriceShow') },
                         { max: 100, message: this.$t('field.tagPriceShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.tagPriceShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.tagPriceShow') },\n                         { max: 100, message: this.$t('field.tagPriceShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.tagPriceShow","placeholder":'请输入'+ this.$t('field.tagPriceShow')},model:{value:(_vm.saleOrderData.saleOrderItemList.tagPriceShow),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "tagPriceShow", $$v)},expression:"saleOrderData.saleOrderItemList.tagPriceShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealPriceShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.dealPriceShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealPriceShow') },
                         { max: 100, message: this.$t('field.dealPriceShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.dealPriceShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealPriceShow') },\n                         { max: 100, message: this.$t('field.dealPriceShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.dealPriceShow","placeholder":'请输入'+ this.$t('field.dealPriceShow')},model:{value:(_vm.saleOrderData.saleOrderItemList.dealPriceShow),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "dealPriceShow", $$v)},expression:"saleOrderData.saleOrderItemList.dealPriceShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.totalAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.totalAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.totalAmountShow') },
                         { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.totalAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.totalAmountShow') },\n                         { max: 100, message: this.$t('field.totalAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.totalAmountShow","placeholder":'请输入'+ this.$t('field.totalAmountShow')},model:{value:(_vm.saleOrderData.saleOrderItemList.totalAmountShow),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "totalAmountShow", $$v)},expression:"saleOrderData.saleOrderItemList.totalAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.dealAmountShow'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.dealAmountShow',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.dealAmountShow') },
                         { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.dealAmountShow',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.dealAmountShow') },\n                         { max: 100, message: this.$t('field.dealAmountShow')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.dealAmountShow","placeholder":'请输入'+ this.$t('field.dealAmountShow')},model:{value:(_vm.saleOrderData.saleOrderItemList.dealAmountShow),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "dealAmountShow", $$v)},expression:"saleOrderData.saleOrderItemList.dealAmountShow"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.unit'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'saleOrderData.saleOrderItemList.unit',
                       {rules: [
                         { required: true, message: '请输入'+this.$t('field.unit') },
                         { max: 100, message: this.$t('field.unit')+'长度小于等于100' }]}
        ]),expression:"[ 'saleOrderData.saleOrderItemList.unit',\n                       {rules: [\n                         { required: true, message: '请输入'+this.$t('field.unit') },\n                         { max: 100, message: this.$t('field.unit')+'长度小于等于100' }]}\n        ]"}],attrs:{"name":"saleOrderData.saleOrderItemList.unit","placeholder":'请输入'+ this.$t('field.unit')},model:{value:(_vm.saleOrderData.saleOrderItemList.unit),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "unit", $$v)},expression:"saleOrderData.saleOrderItemList.unit"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.tags'),"labelCol":{lg: {span: 7}, sm: {span: 7}},"wrapperCol":{lg: {span: 10}, sm: {span: 17} }}},[_c('a-select',{attrs:{"mode":"multiple"},model:{value:(_vm.saleOrderData.saleOrderItemList.tags),callback:function ($$v) {_vm.$set(_vm.saleOrderData.saleOrderItemList, "tags", $$v)},expression:"saleOrderData.saleOrderItemList.tags"}},_vm._l((_vm.codeList.tags),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label)+" ")])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }