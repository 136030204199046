<template>
  <page-header-wrapper>
    <div class="a-card">
      <SaleOrderSearch
        :totalNum="totalNum"
        :queryParam="queryParam"
        :exportLoading="exportLoading"
        :exportWmsLoading="exportWmsLoading"
        :exportModeLoading="exportModeLoading"
        :needExport="false"
        @handleExport="handleExport"
        @handleExportMode="handleExportMode"
      />
      <div class="table-operator">
        <a-button
          type="dashed"
          style="display: none"
          @click="tableOption"
        >{{ (optionAlertShow && '关闭') || '开启' }} alert
        </a-button
        >
        <a-dropdown v-action:edit v-if="selectedRowKeys.length > 0">
          <a-menu slot="overlay">
            <a-menu-item class="hide" key="1" click="handleDeleteSelected">
              <a-icon type="delete"/>
              删除
            </a-menu-item>
            <!-- lock | unlock -->
          </a-menu>
          <a-button style="margin-left: 8px">
            批量操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :dataSource="data"
        :data="loadData"
        :alert="options.alert"
        :rowSelection="options.rowSelection"
        :row-key="(record, index) => index"
        :pagination="{showSizeChanger: true, showQuickJumper: true, pageSize:20, total: 50}"
        :scroll="{ x: true,}"
      >
        <a-table-column :title="$t('columnName.remarkNo')" align="center">
          <template slot-scope="text, record, index">
            {{index+1}}
          </template>
        </a-table-column>
        <a-table-column title="操作" align="center">
          <span slot-scope="record">
            <a @click="handleDetail(record)">{{ $t('button.detail') }}</a>
            <br/>
            <a v-if="record.saleOrderStatus =='待发货' && !record.isSlaveOrder "
            @click="handleImportSingleLogic(record)">{{ $t("button.importLogic") }}</a>
          </span>
        </a-table-column>
        <!-- 订单编号 -->
        <a-table-column :title="$t('columnName.saleOrderNo')" align="left">
          <template slot-scope="record">
            {{record.saleOrderNo}}
          </template>
        </a-table-column>
        <!-- 订单种类 -->
        <a-table-column :title="$t('columnName.isSlaveOrder')" align="left">
          <template slot-scope="record">
            {{record.orderType}}
          </template>
        </a-table-column>
        <!-- 拼单活动ID -->
        <a-table-column :title="$t('columnName.activityId')" align="left">
          <template slot-scope="record">
            {{record.activityId}}
          </template>
        </a-table-column>
        <a-table-column title="裂变活动ID" align="left">
          <template slot-scope="record">
            {{record.slashId}}
          </template>
        </a-table-column>
        <!-- 订单状态 -->
        <a-table-column :title="$t('columnName.saleOrderStatus')" align="center">
          <template slot-scope="record">
            {{record.saleOrderStatus}} <br />
            <span style="font-size:12px">{{ record.orderCancelDesc }}</span>
          </template>
        </a-table-column>
        <!-- 同步WMS状态 -->
        <a-table-column :title="$t('columnName.orderSynStatus')" align="center">
          <template slot-scope="record">
            {{record.orderSynStatus}}
          </template>
        </a-table-column>
        <!-- 订单提交时间 -->
        <a-table-column :title="$t('columnName.saleOrderSubmitTime')" align="center">
          <template slot-scope="record">
            {{formatDateTime(record.saleOrderSubmitTime)}}
          </template>
        </a-table-column>
        <!-- 订单项目列表 -->
        <a-table-column :title="$t('columnName.saleOrderItemList')" align="left">
          <div slot-scope="record">
            <span v-for="(item, idx) in record.saleOrderItemList" :key="idx">
              {{item.skuName}}
              <a-tag v-for="(item_attr, idx_attr) in item.saleAttrList" :key="idx_attr" color="blue">
                [{{ item_attr.attrName }}]-[{{ item_attr.valueName }}]
              </a-tag>
              ({{ item.skuNo }}) * {{ item.quantity }}
              <br/>
            </span>
          </div>
        </a-table-column>
        <!-- 商品付款金额 -->
        <a-table-column :title="$t('columnName.goodsPayAmount')" align="right">
          <template slot-scope="record">
            {{formatTypeMoney(record.goodsPayAmount, record.currentType, record.moneyMultiple)}}
          </template>
        </a-table-column>
        <!-- 邮费付款金额 -->
        <a-table-column :title="$t('columnName.shippingPayAmount')" align="right">
          <template slot-scope="record">
            {{formatTypeMoney(record.shippingDealAmount, record.currentType, record.moneyMultiple)}}
          </template>
        </a-table-column>
        <!-- 商品总金额 -->
        <a-table-column :title="$t('columnName.goodsTotalAmount')" align="right">
          <template slot-scope="record">
            {{formatTypeMoney(record.goodsTotalAmount, record.currentType, record.moneyMultiple)}}
          </template>
        </a-table-column>
        <!-- 联系人 -->
        <a-table-column :title="$t('columnName.contactName')" align="left">
          <template slot-scope="record">
            {{!record.shippingAddress ? '' : record.shippingAddress.contactFirstName + ' ' + record.shippingAddress.contactLastName}}
          </template>
        </a-table-column>
        <!-- 联系人电话 -->
        <a-table-column :title="$t('columnName.contactPhone')" align="left">
          <template slot-scope="record">
            {{!record.shippingAddress ? '' : record.shippingAddress.contactPhone}}
          </template>
        </a-table-column>
       
         <!-- 收件人地址 -->
        <a-table-column :title="$t('columnName.reciveAddr')" align="left">
          <!-- reciveAddr ? reciveAddr :  shippingAddress-->
          <template slot-scope="record">
            {{record.shippingAddress.address ? record.shippingAddress.address : '' }}
          </template>
        </a-table-column>
        
        <!-- 收件人区域 -->
        
       
        <a-table-column title="城市" align="left">
          <template slot-scope="record">
            {{!record.shippingAddress ? '' : record.shippingAddress.city}}
          </template>
        </a-table-column>
        <a-table-column title="州" align="left">
          <template slot-scope="record">
            {{!record.shippingAddress ? '' : record.shippingAddress.state}}
          </template>
        </a-table-column>
         <a-table-column title="邮编" align="left">
          <template slot-scope="record">
            {{!record.shippingAddress ? '' : record.shippingAddress.postCode}}
          </template>
        </a-table-column>
        <a-table-column title="国家" align="left">
          <template slot-scope="record">
            {{!record.shippingAddress ? '' : record.shippingAddress.country}}
          </template>
        </a-table-column>
        <!-- <a-table-column :title="$t('columnName.reciveArea')" align="left">
          
          <template slot-scope="record">
            {{!record.shippingAddress ? '' : record.shippingAddress.state + ' ' + record.shippingAddress.postCode + ' ' + record.shippingAddress.country}}
          </template>
        </a-table-column> -->
         <!-- 更新时间 -->
        <a-table-column :title="$t('columnName.updateTime')" align="center">
          <template slot-scope="record">
            {{formatDateTime(record.updateTime)}}
          </template>
        </a-table-column>
      </s-table>

      <a-modal
        width="50%"
        :title="$t('page.sale_order')"
        :visible="saleOrderModalShow"
        :confirm-loading="confirmEditLoading"
        @ok="handleEditOk"
        @cancel="handleEditCancel"
      >
        <SaleOrderEdit :saleOrderData="saleOrderData"/>
      </a-modal>
      <a-drawer
        :closable="false"
        :visible="showDetailDrawer"
        placement="right"
        width="80%"
        @close="onDetailDrawerClose">
        <SaleOrderDetail
          ref="memberDetail"
          :options="detailOptions"
          :showDetailDrawer="showDetailDrawer"></SaleOrderDetail>
      </a-drawer>
      <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk"/>
      <ApprovalEditor ref="approvalEditor" @handleOk="handleApprovalEditOk"/>
      <a-modal
        width="35%"
        title="输入物流信息"
        :visible="saleOrderShippingImportShow"
        footer=""
        @ok="handleShippingImportOk"
        @cancel="handleShippingImportCancel"
      >
        <SaleOrderShippingImport
          :saleOrderData="saleOrderData"
          :shipParam="shipParam"
          @handleShippingImportOk="handleShippingImportOk"
          @handleShippingImportCancel="handleShippingImportCancel"/>
      </a-modal>
    </div>
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  approvalSaleOrder,
  closeSaleOrder,
  deleteSaleOrder,
  disableSaleOrder,
  enableSaleOrder,
  exportSaleOrderExcel,
  exportSaleOrderExcelMode,
  getSaleOrderCount,
  saveSaleOrderData,
  searchSaleOrder
} from '@/api/order/saleOrder'
import moment from 'moment'
import { STable } from '@/components'

import SaleOrderSearch from '@/views/order/SaleOrderSearch'
import SaleOrderEdit from '@/views/order/components/SaleOrderEdit'
import SaleOrderDetail from '@/views/order/SaleOrderDetail'
import ApprovalEditor from '@/components/approval/ApprovalEditor'
import SaleOrderShippingImport from '@/views/order/components/SaleOrderShippingImport'
import CommentEditor from '@/components/comment/CommentEditor'
import { disableSpu, enableSpu } from '@/api/goods/spu'

export default {
  name: 'SaleOrderList',
  components: {
    STable,
    SaleOrderSearch,
    SaleOrderDetail,
    SaleOrderEdit,
    ApprovalEditor,
    CommentEditor,
    SaleOrderShippingImport
  },
  data () {
    return {
      confirmEditLoading: false,
      showDetailDrawer: false,
      detailOptions: {},

      // 查询参数
      queryParam: { saleOrderStatus: [] },
      exportLoading: false,
      exportWmsLoading: false,
      exportModeLoading: false,
      totalNum: {
        waitPaymentNum: 0,
        inActivityNum: 0,
        waitOutWareNum: 0,
        waitLeaderRecvNum: 0,
        waitActorRecvNum: 0
      },
      data: [],
      status: 'all',
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getSaleOrder(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      saleOrderModalShow: false,
      saleOrderData: {},
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      saleOrderShippingImportShow: false,
      shipParam: {}
    }
  },
  created () {
    this.tableOption()
    let {saleOrderId = null, saleOrderNo = null} = this.$route.query
    if(saleOrderId && saleOrderNo) { //售后单详情弹窗点过来的新开页面 带参打开新的售后单详情
      this.handleDetail({saleOrderId: parseInt(saleOrderId), saleOrderNo})
    }
    // getRoleList({t: new Date()})
  },
  mounted () {},
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      return searchSaleOrder(Object.assign(parameter, this.queryParam)).then((res) => {
        console.log('loadData->searchSaleOrder.parameter', parameter, res)

        getSaleOrderCount(parameter).then((countRes) => {
          console.log('loadData->getSaleOrderCount.parameter', parameter, res)
          this.totalNum = countRes
        })

        return res
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },

    handleImportSingleLogic (record) {
      const that = this
      // that.$message.success("功能暂未实现")
      this.saleOrderData = record
      this.shipParam = {
        logisticsModel: null,
        shippingBillNo: ''
      }
      that.saleOrderShippingImportShow = true
    },
    handleShippingImportOk (record) {
      const that = this
      that.saleOrderShippingImportShow = false
      that.$message.success('导入成功')
      that.$refs.table.refresh(true)
    },

    handleShippingImportCancel (record) {
      const that = this
      that.saleOrderShippingImportShow = false
    },

    handleEditCancel (record) {
      // 弹出编辑页面
      this.sale_orderModalShow = false
    },
    handleEditOk (record) {
      const that = this
      saveSaleOrderData(this.sale_orderData)
        .then((res) => {
          that.$message.success(this.$t('entity.saleOrder.save.success'))
          this.$refs.table.refresh(true)
        })
        .catch((res) => {
          that.$message.error(this.$t('entity.saleOrder.save.fail'))
        })
      this.sale_orderData = {}
      // 弹出编辑页面
      this.sale_orderModalShow = false
    },

    handleCommentEditOk (params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableSpu(record)
          .then(res => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          }).catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableSpu(record)
          .then(res => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解锁定成功')
            return res
          }).catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      }
    },
    handleDetail (record) {
      let {saleOrderId, saleOrderNo} = record
      this.detailOptions = { saleOrderId, saleOrderNo}
      this.showDetailDrawer = true
    },
    handleDisable (record) {
      const that = this
      return disableSaleOrder(record)
        .then((res) => {
          console.log('handleDisable->disableSaleOrder', record, res)
          that.$message.success(this.$t('entity.saleOrder.disable.success'))
          return res
        })
        .catch(() => {
          that.$message.error(this.$t('entity.saleOrder.disable.fail'))
        })
    },
    handleEnable (record) {
      const that = this
      return enableSaleOrder(record)
        .then((res) => {
          console.log('handleEnable->enableSaleOrder', record, res)
          that.$message.success(this.$t('entity.saleOrder.enable.success'))
          return res
        })
        .catch(() => {
          that.$message.error(this.$t('entity.saleOrder.enable.fail'))
        })
    },

    handleClose (record) {
      const that = this
      return closeSaleOrder(record)
        .then((res) => {
          console.log('handleClose->closeSaleOrder', record, res)
          that.$message.success(this.$t('entity.saleOrder.close.success'))
          return res
        })
        .catch(() => {
          that.$message.error(this.$t('entity.saleOrder.close.fail'))
        })
    },

    handleDelete (record) {
      const that = this
      return deleteSaleOrder(record)
        .then((res) => {
          console.log('handleDelete->deleteSaleOrder', record, res)
          that.$message.success(this.$t('entity.saleOrder.delete.success'))
          return res
        })
        .catch(() => {
          that.$message.error(this.$t('entity.saleOrder.delete.fail'))
        })
    },
    handleApproval (record) {
      this.$refs['approvalEditor'].show(record)
    },
    handleApprovalEditOk (params) {
      const record = params.record
      const that = this
      return approvalSaleOrder({
        approvalInfo: record.comment,
        approvalStatus: record.approvalType === 'reject' ? '已拒绝' : '已通过', /** 待审核, 已通过, 已拒绝, 审核中 */
        saleOrderId: record.saleOrderId
      })
        .then(res => {
          console.log('handleApprovalEditOk', record, res)
          that.$refs.table.refresh(true)
          that.$message.success('审批成功')
          return res
        }).catch(() => {
          that.$refs.table.refresh(true)
          that.$message.success('审批失败')
        })
    },
    handleExport (type) {
      const that = this
      const wmsType = type
      console.log('type', wmsType)
      if (wmsType === 'wms') {
        that.exportWmsLoading = true
        console.log('type === wms', wmsType)
      } else {
        that.exportLoading = true
        console.log('type != wms', wmsType)
      }

      const param = {
        type: wmsType
      }
      return exportSaleOrderExcel(param).then(res => {
        if (!res) {
          that.$message.error('导出内容为空')
          return
        }
        // console.log(111);
        const blob = new Blob([res])
        const fileName = '出货清单.xlsx'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE以及IE内核的浏览器
          console.log('IE浏览器')
          navigator.msSaveBlob(blob, fileName)
          // 5秒等待，释放按钮
          if (wmsType === 'wms') {
            setTimeout(() => { that.exportWmsLoading = false }, 5000)
          } else {
            setTimeout(() => { that.exportLoading = false }, 5000)
          }
        } else {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          // 释放URL对象所占资源
          window.URL.revokeObjectURL(url)
          // 用完即删
          document.body.removeChild(link)
          // 5秒等待，释放按钮
          if (wmsType === 'wms') {
            setTimeout(() => { that.exportWmsLoading = false }, 5000)
          } else {
            setTimeout(() => { that.exportLoading = false }, 5000)
          }
        }
      }).catch(() => {
          that.$message.error('导出失败')
          if (wmsType === 'wms') {
            that.exportWmsLoading = false
          } else {
            that.exportLoading = false
          }
        })
    },

    handleExportMode (record) {
      const that = this
      that.exportModeLoading = true
      return exportSaleOrderExcelMode().then(res => {
        if (!res) {
          that.$message.error('下载内容为空')
          return
        }
        // console.log(111);
        const blob = new Blob([res])
        const fileName = '物流信息导入模板.xlsx'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE以及IE内核的浏览器
          console.log('IE浏览器')
          navigator.msSaveBlob(blob, fileName)
          setTimeout(() => { that.exportModeLoading = false }, 5000)
        } else {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          // 释放URL对象所占资源
          window.URL.revokeObjectURL(url)
          // 用完即删
          document.body.removeChild(link)
          // that.exportModeLoading=false
          setTimeout(() => { that.exportModeLoading = false }, 5000)
        }
      }).catch(() => {
          that.$message.error('下载失败')
          that.exportModeLoading = false
        })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      console.log('onSelectChange => ', selectedRowKeys, selectedRows)
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    onDetailDrawerClose () {
     // this.$refs.table.refresh(true)
      this.showDetailDrawer = false
    }

  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
